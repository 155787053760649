import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import $ from 'jquery';

function Contact() {
    const [Content, setContent] = useState(null);
    const [cms, setCms] = useState(null);
    useEffect(() => {
        GetContent();
        getCms();
    }, [])
    const GetContent = async () => {
        await axios.get(`https://esquare.demo-customproject.com:3002/site-info/`).then((res) => {
            setContent(res.data);
        })
    }
    const getCms = async () => {
        await axios.get('https://esquare.demo-customproject.com:3002/get-cms/contact').then(function (res) {
            setCms(res.data);
        })
    }

    const [inquiry, setInquiry] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    })
    const setInquiryParam = (e) => {
        if(e.target.name == "name"){
            var regex = /^[a-zA-Z\s]+$/;
            if (!regex.test(e.target.value)) {
                e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
            }
        }
        if (e.target.name === "phone") {
            var regex = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/;
            if (!regex.test(e.target.value)) {
                e.target.value = e.target.value.replace(/[^\d+-.()\s]/g, '');
            }
        }
        setInquiry((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const submitInquiry = async (e) => {
        e.preventDefault();
        var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regex.test(inquiry.email)) {
            $('#inquiry-response').removeClass('text-success').addClass('text-danger').text('Invalid email address.');
            return false;
        }  else {
            $('#inquiry-response').text('')
        }
        if (inquiry.email != '' && inquiry.email.indexOf('@') > 0 && inquiry.name != '' && inquiry.phone != '' && inquiry.message != '') {
            await axios.post('https://esquare.demo-customproject.com:3002/save-inquiry', inquiry).then(function (response) {
                if (response.data.status == '200') {
                    $('#inquiry-response').removeClass('text-danger').addClass('text-success').text(response.data.message);
                    setTimeout(function () {
                        $('#inquiry-response').text('')
                    }, 2500)
                    $('input').each(function () {
                        $(this).val('')
                    });
                    $('textarea').each(function () {
                        $(this).val('')
                    });
                } else {
                    $('#inquiry-response').removeClass('text-success').addClass('text-danger').text(response.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        } else {
            $('#inquiry-response').removeClass('text-success').addClass('text-danger').text('Fill in all required fields marked with *.');
        }
    }
    if(cms === null){
        return <div className='Loading'></div>
    }
    return (
        <>
            <section className="contact-u">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 centerCol">
                            <div className="contact-heading text-center wow fadeInLeft" data-wow-duration="2s">
                                <h2>{cms.contact_page.title}</h2>
                                <p>{cms.contact_page.subtitle}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4">
                            <div className="cont-box wow fadeInLeft">
                                <img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/location-pin.png" alt />
                                <h2>Location Address</h2>
                                {Content !== null ?
                                    Content.map((item) => {
                                        if (item.type == 'address') {
                                            return <p>{item.value}</p>;
                                        }
                                    })
                                    : <p>301 Park Ave. EAST ORANGE, NEW JERSEY, 07017</p>}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                            <div className="cont-box wow fadeInUp">
                                <img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/telephone.png" alt />
                                <h2>Phone Contact</h2>
                                <div className="phone-email">
                                    {Content !== null ?
                                        Content.map((item) => {
                                            if (item.type == 'phone') {
                                                return <a tel={"tel:" + item.value}>{item.value}</a>;
                                            }
                                        })
                                        : <a href="tel:+12131-03">+12131-03</a>}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                            <div className="cont-box wow fadeInRight">
                                <img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/mail-at.png" alt />
                                <h2>Email Contact</h2>
                                <div className="phone-email">
                                    {Content !== null ?
                                        Content.map((item) => {
                                            if (item.type == 'email') {
                                                return <a tel={"mailto:" + item.value}>{item.value}</a>;
                                            }
                                        })
                                        : <a href="mailto:info@lacrecosmetics.com">info@lacrecosmetics.com</a>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-touch">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 centerCol">
                            <div className="contact-heading text-center wow fadeInLeft" data-wow-duration="2s">
                                <h2>{cms.contact_page.title1}</h2>
                                <p>{cms.contact_page.text1}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row wow fadeInRight">
                        <div className="col-lg-12">
                            <span id="inquiry-response" className='msg'></span>
                            <form id="contact-form" onSubmit={submitInquiry} method="POST">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4">
                                        <div className="any-quest-input">
                                            <label htmlFor="name">Name <span className='text-danger'>*</span></label>
                                            <input type="text" name="name" onChange={setInquiryParam} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="any-quest-input">
                                            <label htmlFor="phone">Phone <span className='text-danger'>*</span></label>
                                            <input type="text" className="Phone" name="phone" onChange={setInquiryParam} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="any-quest-input">
                                            <label htmlFor="email">Email address <span className='text-danger'>*</span></label>
                                            <input type="text" name="email" onChange={setInquiryParam} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 ">
                                        <div className="any-quest-input">
                                            <label htmlFor="message">Message <span className='text-danger'>*</span></label>
                                            <textarea name="message" onChange={setInquiryParam} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 centerCol">
                                        <button type="submit" className="btn-send">Send Now
                                            <i className="fas fa-location-arrow" aria-hidden="true" /></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}

export default Contact