import React from 'react'
import { Link } from 'react-router-dom';
import $ from 'jquery'
import { useEffect, useState } from 'react';
import { useProductContext } from "../context/productcontext";
import secureLocalStorage from "react-secure-storage";
import axios from 'axios';

function Cart() {
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    const [disc, setDics] = useState(0);
    const { products } = useProductContext();
    const [order_summary, SetOrder_Summary] = useState([]);
    const [cartItem, setcartItem] = useState([]);
    useEffect(() => {
        if (secureLocalStorage.getItem('discount') !== null) {
            setDics(parseInt(secureLocalStorage.getItem('discount')))
        } else {
            setDics(0);
        }
    }, [])
    useEffect(() => {
        const Pcart = secureLocalStorage.getItem('Pcart_data');
        if(Pcart != null){
            secureLocalStorage.setItem('cart_data', Pcart);
        }
        orderSummary()
        CartData()
    }, [disc])

    function CartData() {
        var val = secureLocalStorage.getItem('cart_data');
        if (val !== null) {
            let dat = [];
            Object.keys(val).map((item) => {
                let itm = JSON.parse(val[item]);
                dat.push(itm);
            })
            setcartItem(dat);
        }
    }

    function orderSummary() {
        var values = secureLocalStorage.getItem('cart_data');
        if (values !== null) {
            let subt = 0;
            Object.keys(values).map((item) => {
                let itm = JSON.parse(values[item]);
                subt = subt + (itm.price * itm.qunatity);
            })
            let shipping = 0;
            // let tax = 7 * subt / 100;
            // tax = parseInt(tax.toFixed(0));
            const order = {
                subtotal: subt,
                shipping: shipping,
                tax: 0,
                discount: disc,
                total: (subt - (disc * subt / 100)) + shipping,
            }
            secureLocalStorage.setItem("order", JSON.stringify(order));
            const data = JSON.parse(secureLocalStorage.getItem("order"));
            SetOrder_Summary(data);
        }
    }

    const ApplyCounpon = async (e) => {
        e.preventDefault();
        let code = $('.coupon-val').val();
        await axios.get(`https://esquare.demo-customproject.com:3002/coupon-disscount/${code}`).then(res => {
            if (res.data.length == 0) {
                $('.eroor-msg').show();
                setDics(0)
                secureLocalStorage.removeItem('discount');
            } else {
                $('.eroor-msg').hide();
                secureLocalStorage.setItem('discount', res.data[0].discount);
                setDics(parseInt(res.data[0].discount));
            }

        })
    }



    let incrementCounter = (id) => {
        const data = JSON.parse(secureLocalStorage.getItem('cart_data')[id]);
        if (data.qunatity < data?.stock) {
            const cart = {
                ...data, qunatity: data.qunatity + 1,
            }
            secureLocalStorage.setItem('cart_data', {
                ...secureLocalStorage.getItem('cart_data'),
                [id]: JSON.stringify(cart),
            });
            window.dispatchEvent(new Event('storage'))
            orderSummary()
            CartData()
        }
    };
    let decrementCounter = (id) => {
        const data = JSON.parse(secureLocalStorage.getItem('cart_data')[id]);
        if (data.qunatity == 1) { }
        else {
            const cart = {
                ...data, qunatity: data.qunatity - 1,
            }
            secureLocalStorage.setItem('cart_data', {
                ...secureLocalStorage.getItem('cart_data'),
                [id]: JSON.stringify(cart),
            });
            window.dispatchEvent(new Event('storage'))
            orderSummary()
            CartData()
        }
    };

    const Quanti = (e, id) => {
        let val = e.target.value;
        const data = JSON.parse(localStorage.getItem(id));
        if (data.qunatity == 1) { }
        else {
            const cartData = {
                'id': data.id,
                'price': data.price,
                'qunatity': parseInt(val),
            }
            localStorage.setItem(id, JSON.stringify(cartData));
            window.dispatchEvent(new Event('storage'))
            orderSummary()
        }
    }



    const removeItems = (id) => {
        const data = secureLocalStorage.getItem('cart_data');
        delete data[id];
        secureLocalStorage.setItem('cart_data', data);
        orderSummary()
        CartData()
        window.dispatchEvent(new Event('storage'))
    }

    if (order_summary === null || cartItem === null) {
        return <div className='Loading'></div>
    }

    return (
        <>
            <section className="add-to-cart secPadding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 wow fadeInLeft" data-wow-duration="2s">
                            <div className="table-responsive">
                                {cartItem.length !== 0 ? <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Item</th>
                                            <th>Quantity</th>
                                            <th>Unit Price</th>
                                            <th>Sub Price</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cartItem.map((cart) => {
                                                return <>
                                                    {
                                                        products.filter((elem) => {
                                                            return cart.price !== undefined && elem.id === cart.id;
                                                        }).map((elem) => {
                                                            return <tr className="space">
                                                                <td className="col-md-4">
                                                                    <div className="row">
                                                                        <div className="table-space">
                                                                            <div className="row">
                                                                                <div className="col-lg-4 no-padding">
                                                                                    <div className="product-img">
                                                                                        <img src={`https://esquare.demo-customproject.com/admin/backend/public/uploads/products/ath_${elem.user_id}/${elem.thumbnail}`} className="img-fluid" alt="img" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-8 no-space">
                                                                                    <h3>{elem.title}</h3>
                                                                                    <div className='variants-crt'>
                                                                                        {
                                                                                            Object.keys(cart.vartions).map((ver) => {
                                                                                                return <h4>{ver}: <span>{cart.vartions[ver]}</span></h4>
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <h4 className='countyy'>
                                                                        <span onClick={() => {
                                                                            let strg_name = '';
                                                                            Object.keys(cart.vartions).map((ver) => {
                                                                                strg_name = strg_name + cart.vartions[ver];
                                                                            })
                                                                            strg_name = strg_name + cart.id;
                                                                            decrementCounter(strg_name)
                                                                        }}>-</span>
                                                                        <input type="number" disabled value={cart.qunatity} min={1} onChange={(e) => { Quanti(e, cart.id) }} id="qtuDa" className="in-num" defaultValue={1} />
                                                                        <span onClick={() => {
                                                                            let strg_name = '';
                                                                            Object.keys(cart.vartions).map((ver) => {
                                                                                strg_name = strg_name + cart.vartions[ver];
                                                                            })
                                                                            strg_name = strg_name + cart.id;

                                                                            incrementCounter(strg_name)
                                                                        }}>+</span>
                                                                    </h4>
                                                                </td>
                                                                <td>
                                                                    <h4>£{numberWithCommas(cart.price)}</h4>
                                                                </td>
                                                                <td>
                                                                    <h4>£{numberWithCommas(cart.price * cart.qunatity)}</h4>
                                                                </td>
                                                                <td><a href className="remove" onClick={() => {
                                                                    let strg_name = '';
                                                                    Object.keys(cart.vartions).map((ver) => {
                                                                        strg_name = strg_name + cart.vartions[ver];
                                                                    })
                                                                    strg_name = strg_name + cart.id;
                                                                    removeItems(strg_name)

                                                                }}>x</a></td>
                                                            </tr>
                                                        })
                                                    }
                                                </>
                                            })
                                        }
                                    </tbody>
                                </table> : <div className='notfound cart-not text-center'>
                                    <img src='images/cart-empty.png' />
                                    <h2>No items in cart</h2>
                                </div>}
                            </div>
                            {cartItem.length === 0 ? false : <div className="row">
                                <div className="col-sm-12 col-lg-8">
                                    <div className="proceed">
                                        <div className="proc-sec-1">
                                            <form onSubmit={ApplyCounpon}>
                                                <input type="text" className='coupon-val' name placeholder="Coupon Code" />
                                                <button type="submit" className="apply-coupon">Apply Coupon</button>
                                            </form>
                                            <p className='eroor-msg'>Invalid Coupon Code</p>
                                        </div>
                                        <div className="proc-sec-2">
                                            <Link to="/shop" className="gradient">
                                                Continue Purchasing
                                                <span><i className="fa fa-angle-right" aria-hidden="true" /></span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        {cartItem.length === 0 ? false : <div className="col-lg-4 wow fadeInRight" data-wow-duration="2s">
                            <div className="total-section">
                                <ul>
                                    <li>Sub Total <span>£{numberWithCommas(order_summary.subtotal)}</span></li>
                                    <li>Discount <span>{numberWithCommas(order_summary.discount)}%</span></li>
                                    {/* <li>Shipping <span>£{numberWithCommas(order_summary.shipping)}</span></li> */}
                                    <li className="color-change">Total <span>£{numberWithCommas(order_summary.total)}</span></li>
                                </ul>
                            </div>
                            <div className="proc-checkout-btn">
                                <Link to="/checkout" className="checkout-btn">Proceed To Checkout</Link>
                            </div>
                            {/* <div className="checkout-paypal">
                                <a href="#"><img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/paypal-btn.jpg" alt /></a>
                                <div className="paypal-txt">
                                    <p>or checkout with</p>
                                </div>
                            </div> */}
                        </div>}
                    </div>
                </div>
            </section>


        </>
    )
}

export default Cart