import React, { useState } from 'react'
import axios from 'axios';
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { omit } from 'lodash'
import  secureLocalStorage  from  "react-secure-storage";
import { useProductContext } from '../context/productcontext';

function Signup() {
    const navigate = useNavigate();
    const {CheckStripe} = useProductContext()

    useEffect(() => {
        if (secureLocalStorage.getItem('login_data') != null) {
            navigate('/')
        }
    })
    // const [attmp, setAttemp] = useState(0);
    // useEffect(() => {
    //     setAttemp(parseInt(secureLocalStorage.getItem('login_attmpt')));
    // }, [])

    const [login, setLogin] = useState({
        email: '',
        password: '',
    })
    const setLoginParam = (e) => {
        setLogin((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const LoginForm = async (e) => {
        e.preventDefault();
        $('.loginForm input').each(function () {
            if ($(this).val() == '') {
                $(this).addClass('err')
                // return false;
            } else {
                $(this).removeClass('err')
            }
        })
        if (login.email != '' && login.password != '') {
            e.target.classList.add('loadin');
            await axios.post('https://esquare.demo-customproject.com:3002/login', login).then(function (response) {
                if (response.data.status == '200') {
                    $('.loginForm .msg-box').removeClass('red')
                    $('.loginForm .msg-box').addClass('green').text(response.data.message);
                    setLogin({});
                    $('.loginForm input').val('');
                    secureLocalStorage.setItem('login_attmpt', 5);
                    setTimeout(function () {
                        secureLocalStorage.setItem('login_data', JSON.stringify(response.data.data[0]));
                        secureLocalStorage.setItem('accessToken', response.data.accessToken);
                        if(response.data.data[0].role === 2){
                            CheckStripe(response.data.data[0].id, response.data.data[0].email);
                        }
                        if (window.location.href.split('?')[window.location.href.split('?').length - 1] == 'edocrofttp') {
                            window.location.replace('/checkout')
                        } else if (window.location.href.split('?')[window.location.href.split('?').length - 1] == 'edocrofttpd') {
                            window.location.replace('/product-detail/' + window.location.href.split('?')[window.location.href.split('?').length - 2])
                        } else {
                            window.location.replace('/profile')
                            // console.log(response);
                        }
                    }, 1000)
                } else {
                    e.target.classList.remove('loadin');
                    $('.loginForm .msg-box').removeClass('green')
                    $('.loginForm .msg-box').addClass('red').text(response.data.message);
                    // secureLocalStorage.setItem('login_attmpt', parseInt(secureLocalStorage.getItem('login_attmpt')) - 1);
                    // setAttemp(parseInt(secureLocalStorage.getItem('login_attmpt')));
                }
            }).catch(function (error) {
                console.log(error);
            });
        } else {
            e.target.classList.remove('loadin');
            $('.loginForm .msg-box').removeClass('green')
            $('.loginForm .msg-box').addClass('red').text('Empty Fields');
            // secureLocalStorage.setItem('login_attmpt', parseInt(secureLocalStorage.getItem('login_attmpt')) - 1);
            // setAttemp(parseInt(secureLocalStorage.getItem('login_attmpt')));
        }
        e.preventDefault();
    }

    const [user, setUser] = useState({role: 0});
    const [errors, setErrors] = useState({});
    const setVal = (e) => {
        e.persist();
        setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        validate(e.target.name, e.target.value);
    }

    const validate = (name, value) => {
        switch (name) {
            case 'first_name':
                if (value == undefined || value == '') {
                    setErrors({
                        ...errors,
                        first_name: 'Field cannot be empty'
                    })
                } else if (value.length <= 2) {
                    setErrors({
                        ...errors,
                        first_name: 'First name atleast have 3 letters'
                    })
                } else {
                    let newObj = omit(errors, "first_name");
                    setErrors(newObj);
                }
                break;
            case 'last_name':
                if (value == undefined || value == '') {
                    setErrors({
                        ...errors,
                        last_name: 'Field cannot be empty'
                    })
                } else if (value.length <= 2) {
                    setErrors({
                        ...errors,
                        last_name: 'Last name atleast have 3 letters'
                    })
                } else {
                    let newObj = omit(errors, "last_name");
                    setErrors(newObj);
                }
                break;
            case 'email':
                if (
                    !new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
                ) {
                    setErrors({
                        ...errors,
                        email: 'Enter a valid email address'
                    })
                } else {
                    let newObj = omit(errors, "email");
                    setErrors(newObj);
                }
                break;

            case 'password':
                if (
                    !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
                ) {
                    setErrors({
                        ...errors,
                        password: 'Password should contains atleast 8 charaters and containing uppercase,lowercase and numbers'
                    })
                } else {

                    let newObj = omit(errors, "password");
                    setErrors(newObj);

                }
                break;
            case 'confirm_pass':
                if (value !== user.password) {
                    setErrors({
                        ...errors,
                        confirm_pass: "Password does not match"
                    })
                } else {
                    let newObj = omit(errors, "confirm_pass");
                    setErrors(newObj);
                }
                break;

            default:
                break;
        }
    }

    const SendOtp = async (email) => {
        if (email != '') {
            await axios.post('https://esquare.demo-customproject.com:3002/forgot-pass', { email: email }).then(function (response) {
                if (response.data.status == '200') {
                    secureLocalStorage.setItem('forgot_email', JSON.stringify({ email: email }));
                    navigate('/otp-verify');
                } else {
                    $('.signupForm .msg-box').removeClass('green')
                    $('.signupForm .msg-box').addClass('red').text(response.data.message);
                }
            }).catch(function (error) { });
        }
    }

    const addUser = async (e) => {
        e.preventDefault();
        $('.signupForm .msg-box').removeClass('green')
        $('.signupForm .msg-box').removeClass('red')
        if (Object.keys(errors).length === 0) {
            if (user.first_name && user.last_name && user.email && user.password) {
                e.target.classList.add('loadin');
                await axios.post('https://esquare.demo-customproject.com:3002/addUser', { info: user }).then(function (response) {
                    if (response.data.status == '200') {
                        // SendOtp(user.email);
                        e.target.classList.remove('loadin');
                        $('.signupForm .msg-box').removeClass('red')
                        $('.signupForm .msg-box').addClass('green').text(response.data.message);
                        $('.aut-form-box input').val('');
                        secureLocalStorage.setItem('login_data', JSON.stringify(response.data.data[0]));
                        secureLocalStorage.setItem('accessToken', response.data.accessToken);
                        setTimeout(function () {
                            navigate('/')
                        }, 2000)
                    } else {
                        e.target.classList.remove('loadin');
                        $('.signupForm .msg-box').removeClass('green')
                        $('.signupForm .msg-box').addClass('red').text(response.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            } else {
                e.target.classList.remove('loadin');
                $('.signupForm .msg-box').removeClass('green')
                $('.signupForm .msg-box').addClass('red').text("Some Field are Empty");
            }
        } else {
            e.target.classList.remove('loadin');
            $('.signupForm .msg-box').removeClass('green')
            $('.signupForm .msg-box').addClass('red').text("Some Field are Empty");
        }
    }

    useEffect(() => {
        $('.show-pass').unbind().click(function () {
            if ($(this).hasClass('sho')) {
                $(this).prev().attr('type', 'password');
                $(this).removeClass('sho');
            } else {
                $(this).prev().attr('type', 'text');
                $(this).addClass('sho');
            }
        })
    })


    return (
        <>
            <section className="contact-page-main log-in-page-main all-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 wow fadeInLeft" data-wow-delay="0.4s">
                            <div className="log-in-wrap">
                                <h2>Login Your Account</h2>
                                <form className="loginForm" id="login-form" noValidate>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="email" onChange={setLoginParam} placeholder="Email" required />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="password" className="form-control" name="password" onChange={setLoginParam} placeholder="Password" required />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="forgot-pass">
                                            <Link to="/forgot-password">Forgot Password?</Link>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" defaultValue id="rememberCheck" />
                                            <label className="form-check-label" htmlFor="rememberCheck">
                                                Remember me
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-4">
                                        <button onClick={LoginForm} id="btn-login" className="login_btn">LOG IN</button>
                                    </div>
                                    <div className='msg-box'></div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-7 wow fadeInRight" data-wow-delay="0.4s">
                            <div className="log-in-wrap">
                                <h2>Register Your Account</h2>
                                <form id="signupForm" className='signupForm'>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <input className='form-control' type="text" name="first_name" onChange={setVal} id placeholder="First Name" />
                                                {errors.first_name ? <div className='errorr'>{errors.first_name}</div> : false}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <input className='form-control' type="text" name="last_name" onChange={setVal} id placeholder="Last Name" />
                                                {errors.last_name ? <div className='errorr'>{errors.last_name}</div> : false}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input className='form-control' type="email" name="email" onChange={setVal} id placeholder="Email Address" />
                                                {errors.email ? <div className='errorr'>{errors.email}</div> : false}
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div className="form-group">
                                                <input className='form-control' type="phone" name="phone" onChange={setVal} id placeholder="Phone Number" />
                                                {errors.phone ? <div className='errorr'>{errors.phone}</div> : false}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <select className='form-control' name="role" onClick={setVal}>
                                                    <option value={0} selected>Buyer</option>
                                                    <option value={2} >Supplier</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group position-relative">
                                                <input className='form-control' type="password" name="password" onChange={setVal} id placeholder="Enter Password" />
                                                <span className="show-pass"><i className="fa-sharp fa-regular fa-eye"></i><i className="fa-sharp fa-regular fa-eye-slash d-none"></i></span>
                                                {errors.password ? <div className='errorr'>{errors.password}</div> : false}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group position-relative">
                                                <input className='form-control' type="password" name="confirm_pass" id="confirm-pass" onChange={setVal} placeholder="Confirm Password" />
                                                <span className="show-pass"><i className="fa-sharp fa-regular fa-eye"></i><i className="fa-sharp fa-regular fa-eye-slash d-none"></i></span>
                                                {errors.confirm_pass ? <div className='errorr'>{errors.confirm_pass}</div> : false}
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 text-center mt-4">
                                            <button onClick={addUser} id="btn-signup" className="login_btn" disabled={Object.keys(errors).length !== 0 ? true : false}> CREATE ACCOUNT</button>
                                        </div>
                                        <div className='msg-box'></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Signup