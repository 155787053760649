import React from 'react'
import Account_Sidebar from '../components/Account_Sidebar'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import ProductBox from '../components/ProductBox';
import $ from 'jquery'

const WishList = () => {
    if (secureLocalStorage.getItem('login_data') === null) {
        window.location.href = "/login"
    }
    const [products, setProducts] = useState(null);
    useEffect(() => {
        GetWishlist();
    },[])
    const GetWishlist = async () => {
        await axios.post(`https://esquare.demo-customproject.com:3002/get-user-wishlist/${JSON.parse(secureLocalStorage.getItem('login_data')).id}`, null, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
            setProducts(res.data);
            $('.center-sec-icon ul li i.far.fa-heart ~ span').text(res.data.length);
        });
    }
    const RemoveWishlist = async (id) => {
        await axios.post(`https://esquare.demo-customproject.com:3002/alter-wishlist/${JSON.parse(secureLocalStorage.getItem('login_data')).id}/${id}`, null, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
            GetWishlist();
        });
    }
    console.log(products)
    return (
        <>
            <section className="user-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2 p-0">
                            <Account_Sidebar />
                        </div>
                        <div className="col-md-10 p-0">
                            <div className="body-wrapper">
                                <div className='row'>
                                    {
                                        products === null ? <div className='Loading'></div> : (products.length === 0 ? <div className='notfound'>No item in Wishlist</div> : products.map((item, key) => {
                                            return <div className='col-md-3 position-relative'><span className='remove-cross' onClick={()=>{RemoveWishlist(item.id)}}><i className="fa-solid fa-circle-xmark"></i></span> <ProductBox key={item.id} {...item} /></div>
                                         }))
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default WishList