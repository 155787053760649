import React, { useEffect } from 'react'
import secureLocalStorage from 'react-secure-storage'

const ThankYou = () => {
    const style = {
        'filter': 'hue-rotate(223deg) saturate(0.4)',
        'margin-bottom': '-50px',
        'position': 'relative',
        'z-index': '-1',
    }
    useEffect(() => {
        secureLocalStorage.removeItem("order")
        secureLocalStorage.removeItem("cart_data")
        secureLocalStorage.removeItem("discount")
    }, [])
    return (
        <div className='notfound d-block cart-not text-center'>
            <img src='https://cdn.shopify.com/s/files/1/0577/8181/3453/files/truck.gif?v=1643905486' style={style} />
            <h2>Your Order is <br /> Placed Successfully</h2>
        </div>
    )
}

export default ThankYou