import axios from 'axios';
import React, { useEffect, useState } from 'react'

const Testimonials = () => {
    const [testi, setTesti] = useState(null);
    const getTesti = async () => {
        await axios.get('https://esquare.demo-customproject.com:3002/get-cms-testimonails').then(function (res) {
            setTesti(res.data);
        })
    }
    useEffect(() => {
        getTesti();
    }, []);
    if (testi === null) {
        return <div className='Loading'></div>
    }
    return (
        <section className='test-sec-innr pt-5 pb-5'>
            <div className='container'>
                <div class="row">
                    <div class="col-lg-8 centerCol">
                        <div class="contact-heading text-center wow fadeInLeft" data-wow-duration="2s">
                            <h2>Testimonials</h2>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    {
                        testi.map((item) => {
                            return <div className='col-md-6'>
                                <div className="people-txt">
                                    <div className="testi-quote">
                                        <img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/quote.png" alt="quote" />
                                    </div>
                                    <ul>
                                        <li><img src={`https://esquare.demo-customproject.com/admin/backend/public/uploads/category/${item.image}`} alt="img" /></li>
                                        <li>
                                            <h5>{item.name}</h5>
                                            <h6>{item.profession}</h6>
                                        </li>
                                    </ul>
                                    <p>{item.comment}</p>
                                    <div className="star--list">
                                        {
                                            (() => {
                                                const arr = [];
                                                for (let i = 0; i < 5; i++) {
                                                    if (i < item.rating) {
                                                        arr.push(<a><i className="fas fa-star" /></a>)
                                                    } else {
                                                        arr.push(<a><i className="fa-light fa-star" /></a>)
                                                    }
                                                }
                                                return arr;
                                            })()
                                        }
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default Testimonials