const ProductReducer = (state, action) => {
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...state,
                isloading: true,
            };
        case "IS_ERROR":
            return {
                ...state,
                isloading: false,
                isError: true,
            };
        case "MY_PRODUCTS":
            const featuredPRD = action.payload;
            const PRD = action.payload;
            return {
                ...state,
                isloading: false,
                products: PRD,
                featured: featuredPRD,
            }
        case "MY_PRODUCTS_NEW":
            return {
                ...state,
                newArrival: action.payload,
            }
        case "IS_LOADING_DETAIL":
            return {
                ...state,
                isloadingDet: true,
            }
        case "MY_PRODUCTS_DETAIL":
            return {
                ...state,
                isloadingDet: false,
                productData: action.payload,
            }
        case "MY_PRODUCTS_ONE":
            return {
                ...state,
                productOne: action.payload,
            }
        case "MY_PRODUCTS_CAT":
            return {
                ...state,
                getCatg: action.payload,
            }
        case "PAR_PRODUCTS_CAT":
            return {
                ...state,
                getParCatg: action.payload,
            }
        case "STRIPE_URL":
            return {
                ...state,
                stripeURL: action.payload,
            }
            
        default:
            return state;
    }
}

export default ProductReducer;