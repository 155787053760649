import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Notfound from './pages/Notfound'
import Home from './pages/Home';
import Header from './components/Header';
import ScrollTop from './components/ScrollTop';
import Footer from './components/Footer';
import Scripts from './components/Scripts';
import Shop from './pages/Shop';
import Men from './pages/Men';
import Women from './pages/Women';
import Accessories from './pages/Accessories';
import Wedding from './pages/Wedding';
import Contact from './pages/Contact';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import Signup from './pages/Signup';
import Product_detail from './pages/Product_detail';
import $ from 'jquery'
import axios from 'axios';
import React, { useEffect } from 'react'
import OTP_varify from './pages/OTP_varify';
import Forgot_Password from './pages/Forgot_Password';
import Profile from './pages/Profile';
import Add_Products from './pages/Add_Products';
import Vendor_Detail from './pages/Vendor_Detail';
import Search from './pages/Search';
import My_Store from './pages/My_Store';
import Category from './pages/Category';
import ThankYou from './pages/ThankYou';
import Orders from './pages/Orders';
import Order_detail from './pages/Order_detail';
import Edit_Product from './pages/Edit_Product';
import Order_Supplier from './pages/Order_Supplier';
import WishList from './pages/WishList';
import Order_detail_supplier from './pages/Order_detail_supplier';
import About from './pages/About';
import Faqs from './pages/Faqs';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Testimonials from './pages/Testimonials';
import JustCategory from './pages/JustCategory';
import Stripe_Alert from './components/Stripe_Alert';

function App() {

    const getLogo = async () => {
        await axios.get('https://esquare.demo-customproject.com:3002/getfav').then(res => {
            $('link[rel="icon"]').attr('href', 'https://esquare.demo-customproject.com/admin/backend/public/uploads/' + res.data[0].img_path);
        })
    }
    useEffect(() => {
        getLogo();
    }, [])

    return (
        <>
            <BrowserRouter>
                <Header />
                <Stripe_Alert />
                <ScrollTop />
                <Routes>
                    <Route path="*" element={<Notfound />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/shop" element={<Shop />} />
                    <Route path="/men" element={<Men />} />
                    <Route path="/women" element={<Women />} />
                    <Route path="/thankyou" element={<ThankYou />} />
                    <Route path="/accessories" element={<Accessories />} />
                    <Route path="/wedding" element={<Wedding />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/cart" element={<Cart />} />
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/login" element={<Signup />} />
                    <Route path="/product-detail/:id" element={<Product_detail />} />
                    <Route path='/otp-verify' element={<OTP_varify />} />
                    <Route path='/forgot-password' element={<Forgot_Password />} />
                    <Route path='/profile' element={< Profile />} />
                    <Route path='/add-products' element={< Add_Products />} />
                    <Route path='/edit-products/:id' element={< Edit_Product />} />
                    <Route path='/vendor-detail/:id' element={< Vendor_Detail />} />
                    <Route path='/search/:query' element={< Search />} />
                    <Route path='/my-store/' element={< My_Store />} />
                    <Route path='/category/:type/:cate' element={< Category />} />
                    <Route path='/product-category/:type/:cate' element={< JustCategory />} />
                    <Route path='/category/:type' element={<Category />} />
                    <Route path='/order-detail/:id' element={< Order_detail />} />
                    <Route path='/order-recieved/:id' element={< Order_detail_supplier />} />
                    <Route path='/orders-listing/' element={< Order_Supplier />} />
                    <Route path='/wishlist' element={<WishList />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/faqs' element={<Faqs />} />
                    <Route path='/privacy' element={<Privacy />} />
                    <Route path='/terms' element={<Terms />} />
                    <Route path='/testimonails' element={<Testimonials />} />
                </Routes>
                <Footer />
                <Scripts />
            </BrowserRouter>
        </>

    );
}

export default App;