import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import $ from 'jquery';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useProductContext } from '../context/productcontext';
import ProductBox from '../components/ProductBox';
const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });


const Home = () => {
    const [cms, setCms] = useState(null);
    const [testi, setTesti] = useState(null);
    const [banner, setBanner] = useState(null);
    const { products, getCatg, getParCatg } = useProductContext();
    useEffect(() => {
        var $slider = $(".testi-slider ");
        var $progressBar = $(".progress");
        var $progressBarLabel = $(".slider__label");

        $slider.on("beforeChange", function (event, slick, currentSlide, nextSlide) {
            var calc = (nextSlide / (slick.slideCount - 1)) * 100;
            console.log(calc);
            $progressBarLabel.width(calc + "%");
        });
    })

    useEffect(() => {
        getCms();
        getTesti();
        GetBanner();
    }, [])

    const getCms = async () => {
        await axios.get('https://esquare.demo-customproject.com:3002/get-cms/home').then(function (res) {
            setCms(res.data);
        })
    }
    const getTesti = async () => {
        await axios.get('https://esquare.demo-customproject.com:3002/get-cms-testimonails').then(function (res) {
            setTesti(res.data);
        })
    }

    const GetBanner = async () => {
        await axios.get('https://esquare.demo-customproject.com:3002/get-banner').then(function (res) {
            setBanner(res.data);
        })
    }

    var setting4 = {
        dots: false,
        arrows: true,
        infinite: false,
        autoplay: true,
        speed: 900,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }

    var setting2 = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
    }


    var setting3 = {
        dots: true,
        arrows: false,
        draggable: false,
        infinite: true,
        speed: 300,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    var setting5 = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    var setting6 = {
        dots: false,
        arrows: true,
        infinite: true,
        autoplay: true,
        speed: 300,
        slidesToShow: 8,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }

    if (Object.keys(products).length === 0 || getCatg === null || Object.keys(getCatg).length === 0 || getParCatg === null || Object.keys(getParCatg).length === 0 || testi === null || cms === null || banner === null) {
        return <div className='Loading'></div>
    }

    return (
        <>
            {banner.length === 0 ? false : <section className="main_slider">
                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="carousel-caption">
                                <Slider {...setting3} className='new-banner-slider'>
                                    {
                                        banner.map((item) => {
                                            return <div>
                                                <div className="banimg-slider-item">
                                                    <a href={item.button_link}><img src={`https://esquare.demo-customproject.com/admin/backend/public/uploads/banner/${item.image_path}`} alt /></a>
                                                </div>
                                            </div>
                                        })
                                    }
                                </Slider>
                            </div>
                        </div>
                        <div className="stars-animation">
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                            <div className="snowflake" />
                        </div>
                    </div>
                </div>
            </section>}

            <section className="Explore-millions all-section">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-lg-7">
                            <div className="explore-text">
                                <h2>{cms.Below_Banner.title}</h2>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            {renderHTML(cms.Below_Banner.description)}
                        </div>
                    </div>
                </div>
            </section>


            <section className="industry-wrap all-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Slider {...setting6} classname="industry.slider">
                                {
                                    getCatg.map((item, key) => {
                                        if (key % 2 === 0 && getCatg[key + 1] !== undefined) {
                                            return <div>
                                                <div className="industry-box">
                                                    <Link to={`/product-category/${getParCatg.filter((cat) => {
                                                        return cat.id.toString() === item.parent_id.toString()
                                                    }).map((cat) => {
                                                        return cat.name.toLowerCase()
                                                    })
                                                        }/${getCatg[key].name.toLowerCase()}`}>
                                                        <img src={`https://esquare.demo-customproject.com/admin/backend/public/uploads/category/${getCatg[key].image}`} alt />
                                                        <p>{getCatg[key].name}</p>
                                                    </Link>
                                                </div>
                                                <div className="industry-box">
                                                    <Link to={`/product-category/${getParCatg.filter((cat) => {
                                                        return cat.id.toString() === item.parent_id.toString()
                                                    }).map((cat) => {
                                                        return cat.name.toLowerCase()
                                                    })
                                                        }/${getCatg[key + 1].name.toLowerCase()}`}>
                                                        <img src={`https://esquare.demo-customproject.com/admin/backend/public/uploads/category/${getCatg[key + 1].image}`} alt />
                                                        <p>{getCatg[key + 1].name}</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        }
                                    })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>




            <section className="featured-product">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 centerCol">
                            <div className="sec-heading text-center">
                                <h2>{cms.after_category.title}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Slider {...setting4} className='new-collection-slider1'>
                            {
                                products.map((prd, key) => {
                                    if (key < 10) {
                                        return <ProductBox {...prd} />
                                    }
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </section>

            <section className="custom-cat all-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col=md-12">
                            <div className="custom-dress">
                                <img src={`https://esquare.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.wedding_left_side.image_path}`} alt="img" />
                                <a to="/wedding">
                                    <h2>{cms.wedding_left_side.title}</h2>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <Slider {...setting5} className='new-collection-slider2'>
                                        <div>
                                            <div className="wed-slider-item">
                                                <img src={`https://esquare.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.women_slider1.image_path}`} alt />
                                                <h2>{cms.women_slider1.title}</h2>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="wed-slider-item">
                                                <img src={`https://esquare.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.women_slider2.image_path}`} alt />
                                                <h2>{cms.women_slider2.title}</h2>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                                <div className="col-md-12">
                                    <Slider {...setting5} className='new-collection-slider2'>
                                        <div>
                                            <div className="wed-slider-item">
                                                <img src={`https://esquare.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.men_slider1.image_path}`} alt />
                                                <h2>{cms.men_slider1.title}</h2>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="wed-slider-item">
                                                <img src={`https://esquare.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.women_slider2.image_path}`} alt />
                                                <h2>{cms.women_slider2.title}</h2>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
                getParCatg.map((cate) => {
                    let a = 0;
                    products.filter((item) => {
                        if (item.type_category == cate.id) {
                            a = 1;
                        }
                    })
                    if (a === 1) {
                        return <section className="featured-product pt-4 pb-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 centerCol">
                                        <div className="sec-heading text-center">
                                            <h2>For {cate.name}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <Slider {...setting4} className='new-collection-slider1'>
                                        {
                                            products.filter((item) => {
                                                return item.type_category == cate.id;
                                            }).map((item, key) => {
                                                if (key < 10) {
                                                    return <ProductBox {...item} />
                                                }
                                            })
                                        }
                                    </Slider>
                                </div>
                            </div>
                        </section>
                    } else {
                        return false;
                    }

                })
            }




            {testi.length === 0 ? false : <section className="people-sec">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-10 col-md-12 col-12 centerCol">
                            <div className="people-head text-center">
                                <h3>What People Say</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 col-12 p-0">
                            <div className="testi-img">
                                <img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/testi.jpg" alt="img" />
                                <div className="testi-txt">
                                    <h5>Testimonials</h5>
                                </div>
                            </div>
                            <div className="nike-zoom-progress-barchg">
                                <div className="progress" role="progressbar" aria-valuemin={0} aria-valuemax={100}>
                                    <span className="slider__label sr-only">
                                    </span></div>
                            </div>
                            <div className="nike-zoom-silder-number">
                                <div className="slide_counter">
                                    <div>
                                        <h2>
                                            <span className="start_number">01</span>
                                            <span className="end_number" />
                                        </h2>
                                    </div>
                                    <div>
                                        <h1><span className="start_number">01</span></h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 p-0">
                            <Slider {...setting2} className='testi-slider'>
                                {
                                    testi.map((item) => {
                                        return <div>
                                            <div className="people-txt">
                                                <div className="testi-quote">
                                                    <img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/quote.png" alt="quote" />
                                                </div>
                                                <ul>
                                                    <li><img src={`https://esquare.demo-customproject.com/admin/backend/public/uploads/category/${item.image}`} alt="img" /></li>
                                                    <li>
                                                        <h5>{item.name}</h5>
                                                        <h6>{item.profession}</h6>
                                                    </li>
                                                </ul>
                                                <p>{item.comment}</p>
                                                <div className="star--list">
                                                    {
                                                        (() => {
                                                            const arr = [];
                                                            for (let i = 0; i < 5; i++) {
                                                                if (i < item.rating) {
                                                                    arr.push(<a><i className="fas fa-star" /></a>)
                                                                } else {
                                                                    arr.push(<a><i className="fa-light fa-star" /></a>)
                                                                }
                                                            }
                                                            return arr;
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>}
        </>
    )
}


export default Home
