import React from 'react'
import { useProductContext } from '../context/productcontext'

const Stripe_Alert = () => {
    const { stripeURL } = useProductContext();
    if(!stripeURL) return false;
    return (
        <div id="stripeModal" className="stripe-modal">
            <div className="stripe-modal-content">
                <h2 className="stripe-modal-heading">Alert</h2>
                <p className="stripe-modal-body">To proceed, please connect your Stripe account. This step is necessary to complete your transactions securely.</p>
                <a href={stripeURL} className="stripe-connect-button" target="_blank">Connect Stripe Account</a>
            </div>
        </div>
    )
}

export default Stripe_Alert