import React, { useEffect, useState } from 'react'
import Account_Sidebar from '../components/Account_Sidebar';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import ProductBox from '../components/ProductBox';
import { Link } from 'react-router-dom';

const My_Store = () => {
    if (secureLocalStorage.getItem('login_data') == null) {
        window.location.replace('/login');
    }
    const [products, setProducts] = useState(null);
    const [searchQ, SetSearchQ] = useState(null);
    const user_id = JSON.parse(secureLocalStorage.getItem('login_data')).id;
    const GetProducts = async () => {
        await axios.post(`https://esquare.demo-customproject.com:3002/get-supplier-products`, { user_id: user_id }, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
            setProducts(res.data)
        })
    }

    useEffect(() => {
        GetProducts();
    }, [])

    if (products === null) {
        return <div className='Loading'></div>
    }

    return (
        <section className="user-area">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-2 p-0"><Account_Sidebar /></div>
                    <div className="col-md-10 p-0">
                        <div className="body-wrapper">
                            <div className="userInfo">
                                <div className='switch-box-head'>
                                    <h3>My Store</h3>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className='box-dahsbrd' style={{ backgroundColor: '#d3d8ff' }}>
                                            <i class="fa-solid fa-chart-line-up"></i>
                                            <div>
                                                <h4>5000</h4>
                                                <span>Products Sold</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='box-dahsbrd' style={{ backgroundColor: '#dcffd3' }}>
                                            <i class="fa-solid fa-cubes"></i>
                                            <div>
                                                <h4>5000</h4>
                                                <span>Orders</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='box-dahsbrd' style={{ backgroundColor: '#fffcd3' }}>
                                            <i class="fa-solid fa-user"></i>
                                            <div>
                                                <h4>5000</h4>
                                                <span>Customers</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='box-dahsbrd' style={{ backgroundColor: '#ffd3d3' }}>
                                            <i class="fa-sharp fa-solid fa-dollar-sign"></i>
                                            <div>
                                                <h4>$50000</h4>
                                                <span>Revenue</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='switch-box-head mt-5'>
                                    <h3>My Products</h3>
                                    <div className='serach-boxxs'>
                                        <input type='text' placeholder='Search Products' onKeyUp={(e) => { SetSearchQ(e.target.value) }} />
                                    </div>
                                </div>
                                <div className='row'>
                                    {
                                        products.length === 0 ? <div className='notfound'>No Products Uploaded yet</div>
                                            : products.filter((item) => {
                                                if (searchQ === null || searchQ === '') {
                                                    return item;
                                                } else {
                                                    return item.title.toLowerCase().includes(searchQ.toLowerCase());
                                                }
                                            }).map((item) => {
                                                return <div className='col-lg-2 col-md-3 mb-4 position-relative'>
                                                    <ProductBox {...item} />
                                                    <div className='action-btnss'>
                                                        <Link to={`/edit-products/${item.id}`}>Edit</Link>
                                                        <button>Delete</button>
                                                    </div>
                                                </div>
                                            })
                                    }
                                    <div className='notfound'>No Products Found</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default My_Store