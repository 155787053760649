import React from 'react'
import Account_Sidebar from '../components/Account_Sidebar'
import { useState, useEffect } from 'react'
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { useProductContext } from '../context/productcontext';
import { useParams } from 'react-router-dom';
import $ from 'jquery'

const Order_detail = () => {
    if (secureLocalStorage.getItem('login_data') == null || JSON.parse(secureLocalStorage.getItem('login_data')).role != 0) {
        window.location.replace('/login');
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const PINT = urlParams.get('payment_intent');
    const PISC = urlParams.get('payment_intent_client_secret');
    
    const { id } = useParams();
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    const { products } = useProductContext();
    const [data, setData] = useState(null);
    const GetInvoice = (id) => {
        axios.get(`https://esquare.demo-customproject.com:3002/get-orders-single/${id}`, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((resp) => {
            let order = resp.data;
            axios.get(`https://esquare.demo-customproject.com:3002/get-orders-items/${id}`, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
                setData({ order: order[0], order_items: res.data });
            })
        })

    }
    const CheckPayment = async () => {
        await axios.post(`https://esquare.demo-customproject.com:3002/check-payment/`, {PINT : PINT, PISC : PISC, ID : id}).then((res) => {
            if (res.data.status == '200') {
                $('.msg-box').removeClass('red')
                $('.msg-box').addClass('green').text(res.data.message);
                secureLocalStorage.removeItem("order")
                secureLocalStorage.removeItem("cart_data")
                secureLocalStorage.removeItem("discount")
            } else {
                $('.msg-box').removeClass('green')
                $('.msg-box').addClass('red').text(res.data.message);
            }
        })
    }
    useEffect(() => {
        GetInvoice(id);
        if(PINT !== null){
            CheckPayment();
        }
    }, [id])
    return (
        <section className="user-area">
            <div className="container-fluid">
                <div className="row justify-content-end">
                    <div className="col-md-2 p-0"><Account_Sidebar /></div>
                    <div className="col-md-10 p-0">
                        <div className='invoice-popup open'>
                            <div className="card">
                                <div className="card-body mx-4">
                                    <div className='msg-box'></div>
                                    {
                                        data === null ? <div className='Loading'></div> : <>
                                            <p className="my-3 mb-4" style={{ fontSize: 30 }}>{data.order.order_number} <span>Status: <b>{data.order.order_status}</b></span></p>
                                            <div className="row">
                                                <ul className="list-unstyled col-md-4">
                                                    <li className="text-black">Buyer Name: <b> {data.order.billing_first_name + ' ' + data.order.billing_last_name}</b></li>
                                                    <li className="text-black">contact: <b> {data.order.billing_contact}</b></li>
                                                    <li className="text-black">email: <b> {data.order.billing_email}</b></li>
                                                    <li className="text-black">country: <b> {data.order.billing_country}</b></li>
                                                </ul>
                                                <ul className="list-unstyled col-md-4">
                                                    <li className="text-black">address: <b> {data.order.billing_address1}</b></li>
                                                    <li className="text-black">state: <b> {data.order.billing_state}</b></li>
                                                    <li className="text-black">city: <b> {data.order.billing_city}</b></li>
                                                    <li className="text-black">Zip: <b> {data.order.billing_zipcode}</b></li>
                                                </ul>
                                                <ul className="list-unstyled col-md-4">
                                                    <li className="text-black">Discount: <b> {data.order.coupon}%</b></li>
                                                    <li className="text-black">shipping: <b> £{numberWithCommas(data.order.shipping)}</b></li>
                                                    <li className="text-black">subtotal: <b> £{numberWithCommas(data.order.subtotal)}</b></li>
                                                    <li className="text-black">total: <b> £{numberWithCommas(data.order.total)}</b></li>
                                                </ul>
                                            </div>
                                            <div className="table-responsive">
                                                {data.order_items.length !== 0 ? <table className="table border">
                                                    <thead>
                                                        <tr>
                                                            <th>Item</th>
                                                            <th>Quantity</th>
                                                            <th>Unit Price</th>
                                                            <th>Sub Price</th>
                                                            <th>Tracking Code</th>
                                                            <th>Label</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data.order_items.map((cart) => {
                                                                return <>
                                                                    {
                                                                        products.filter((elem) => {
                                                                            return elem.id === cart.item_id;
                                                                        }).map((elem) => {
                                                                            console.log(JSON.parse(cart.shipment_data))
                                                                            const shipment_data = JSON.parse(cart.shipment_data);
                                                                            return <tr className="space">
                                                                                <td className="col-md-4">
                                                                                    <div className="row">
                                                                                        <div className="table-space">
                                                                                            <div className="row">
                                                                                                <div className="col-lg-3 no-padding">
                                                                                                    <div className="product-img">
                                                                                                        <img src={`https://esquare.demo-customproject.com/admin/backend/public/uploads/products/ath_${elem.user_id}/${elem.thumbnail}`} className="img-fluid" alt="img" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-lg-9 no-space">
                                                                                                    <h3>{elem.title}</h3>
                                                                                                    <div className='variants-crt m-0'>
                                                                                                        {
                                                                                                            (() => {
                                                                                                                let variant = JSON.parse(cart.variations);
                                                                                                                return Object.keys(variant).map((ver) => {
                                                                                                                    return <h4>{ver}: <span>{variant[ver]}</span></h4>
                                                                                                                })
                                                                                                            })()
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <h4 className='m-0'>{cart.quantity}</h4>
                                                                                </td>
                                                                                <td>
                                                                                    <h4 className='m-0'>£{numberWithCommas(cart.price)}</h4>
                                                                                </td>
                                                                                <td>
                                                                                    <h4 className='m-0'>£{numberWithCommas(cart.price * cart.quantity)}</h4>
                                                                                </td>
                                                                                <td>
                                                                                    <h4 className='m-0 small'>{shipment_data.tracking_codes[0]}</h4>
                                                                                    <a href={shipment_data.tracking_urls[0]} target='_blank' className='btn-new'>Track</a>
                                                                                </td>
                                                                                <td>
                                                                                    <a href={`https://esquare.demo-customproject.com/admin/backend/label_storage/${shipment_data.saved_file_path}`} target='_blank' className='btn-new'>View Label</a>
                                                                                </td>
                                                                            </tr>
                                                                        })
                                                                    }
                                                                </>
                                                            })
                                                        }
                                                    </tbody>
                                                </table> : <div className='notfound cart-not text-center'>
                                                    <img src='images/cart-empty.png' />
                                                    <h2>No items in cart</h2>
                                                </div>}
                                            </div>
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Order_detail