import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import $ from 'jquery'

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    // $('html').addClass('loading-body');
    $('.mobile-nav').removeClass('open');
    // setTimeout(() => {
    //   $('html').removeClass('loading-body');
    // },1000)
  }, [pathname]);

  return null;
}
