import React from 'react'
import { Link } from 'react-router-dom'

function Wedding() {
    return (
        <>
            <section className="inner-banner">
                <div className="container">
                    <div className="row">
                        <div className=" col-sm-6 col-md-6 align-self-end">
                            <div className="banner-categories1">
                                <div className="inner-banner-heading">
                                    <h1>Wedding</h1>
                                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur cepteur sint occaecat cupidatat non proid.</p>                      </div>
                            </div>
                        </div>
                        <div className=" col-sm-6 col-md-6 align-self-center">
                            <div className="banner_img1">
                                <img src="https://demo-customlinks.com/esquare_dev/assets/uploads/inner_banner/banner-girl169361785281.png" className="img-fluid wow bounceIn" data-wow-duration="2s" alt />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="stars-animation">
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                    <div className="snowflake" />
                </div>
            </section>

            <section className="productsec product-page-sec all-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <div className="filter-sec">
                                {/* <div class="catogeriesbox wow fadeInLeft">
                      <h4>CATEGORIES</h4>
                  </div> */}
                                <div className="frequently-list wow fadeInLeft">
                                    <div className="panel-groupnew" id="accordion" role="tablist" aria-multiselectable="true">
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="headingOne">
                                                <h4 className="panel-title catogeriesbox">
                                                    <a role="button" className="hide-btn" data-bs-toggle="collapse" href="#headingone" aria-expanded="false"> Categories </a>
                                                </h4>
                                            </div>
                                            <div className="collapse show" id="headingone">
                                                <div className="panel-body categories-panelbody">
                                                    <ul>
                                                        <li>
                                                            <input type="checkbox" className="categories-checkbox" />
                                                            <a href="#">Lorem Ipsum </a>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" className="categories-checkbox" />
                                                            <a href="#">Dolor Sit </a>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" className="categories-checkbox" />
                                                            <a href="#">Adipcing Elit </a>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" className="categories-checkbox" />
                                                            <a href="#">Tempor Incidunt </a>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" className="categories-checkbox" />
                                                            <a href="#">Ut Labor</a>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" className="categories-checkbox" />
                                                            <a href="#">Incidunt Ut</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="catogeriesbox mt-4 wow fadeInLeft">
                      <h4>PRICE FILTER</h4>
                  </div> */}
                                <div className="frequently-list wow fadeInLeft">
                                    <div className="panel-groupnew " id="accordion" role="tablist" aria-multiselectable="true">
                                        <div className="panel panel-default">
                                            <div className="panel-heading " role="tab" id="headingOne2">
                                                <h4 className="panel-title catogeriesbox">
                                                    <a role="button" className="hide-btn" data-bs-toggle="collapse" href="#headingtwo2" aria-expanded="false"> Price Range </a>
                                                </h4>
                                            </div>
                                            <div className="collapse show" id="headingtwo2">
                                                <div className="panel-body">
                                                    <ul>
                                                        <li>
                                                            <div className="list-div-price-range">
                                                                <input type="checkbox" className="freq-checkbox" />
                                                                <a href="#" className="price-rang">$0.00</a>
                                                                <span className="dash"> - </span>
                                                                <span className="dollar">$25 </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="list-div-price-range">
                                                                <input type="checkbox" className="freq-checkbox" />
                                                                <a href="#" className="price-rang">$25</a>
                                                                <span className="dash"> - </span>
                                                                <span className="dollar">$50 </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="list-div-price-range">
                                                                <input type="checkbox" className="freq-checkbox" />
                                                                <a href="#" className="price-rang">$50</a>
                                                                <span className="dash"> - </span>
                                                                <span className="dollar">$75 </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="list-div-price-range">
                                                                <input type="checkbox" className="freq-checkbox" />
                                                                <a href="#" className="price-rang">$75</a>
                                                                <span className="dash"> - </span>
                                                                <span className="dollar">$100 </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="list-div-price-range">
                                                                <input type="checkbox" className="freq-checkbox" />
                                                                <a href="#" className="price-rang">$100</a>
                                                                <span className="dash"> - </span>
                                                                <span className="dollar">$120 </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="list-div-price-range">
                                                                <input type="checkbox" className="freq-checkbox" />
                                                                <a href="#" className="price-rang">$120</a>
                                                                <span className="dash"> - </span>
                                                                <span className="dollar">$140 </span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 prdt-pg-col">
                            <div className="row wow fadeInRight">
                                <div className="col-lg-4">
                                    <div className="product-item mt-4">
                                        {/* <a href="https://demo-customlinks.com/esquare_dev/detail"> */}
                                        <h4> <span className="price">$150</span> <span className="dis-price">$100</span></h4>
                                        <div className="rate">
                                            <input type="radio" id="star1" name="rate" defaultValue={1} />
                                            <label htmlFor="star1" title="text">1 star</label>
                                            <input type="radio" id="star2" name="rate" defaultValue={2} />
                                            <label htmlFor="star2" title="text">2 stars</label>
                                            <input type="radio" id="star3" name="rate" defaultValue={3} />
                                            <label htmlFor="star3" title="text">3 stars</label>
                                            <input type="radio" id="star4" name="rate" defaultValue={4} />
                                            <label htmlFor="star4" title="text">4 stars</label>
                                            <input type="radio" id="star5" name="rate" defaultValue={5} />
                                            <label htmlFor="star5" title="text">5 stars</label>
                                        </div>
                                        <img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/women/women-2.jpg" alt="Product" className="prod-img" />
                                        <Link to="/Product_detail">
                                            <h3>Lorem ipsum dolor sit
                                                amet conse.</h3>
                                        </Link>
                                        <Link to="/cart" className="prod-cart"><img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/prod-cart.png" alt="prod-cart" /></Link>
                                        {/* </a> */}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="product-item mt-4">
                                        {/* <a href="https://demo-customlinks.com/esquare_dev/detail"> */}
                                        <h4> <span className="price">$150</span> <span className="dis-price">$100</span></h4>
                                        <div className="rate">
                                            <input type="radio" id="star1" name="rate" defaultValue={1} />
                                            <label htmlFor="star1" title="text">1 star</label>
                                            <input type="radio" id="star2" name="rate" defaultValue={2} />
                                            <label htmlFor="star2" title="text">2 stars</label>
                                            <input type="radio" id="star3" name="rate" defaultValue={3} />
                                            <label htmlFor="star3" title="text">3 stars</label>
                                            <input type="radio" id="star4" name="rate" defaultValue={4} />
                                            <label htmlFor="star4" title="text">4 stars</label>
                                            <input type="radio" id="star5" name="rate" defaultValue={5} />
                                            <label htmlFor="star5" title="text">5 stars</label>
                                        </div>
                                        <img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/wedding/wedding-1.jpg" alt="Product" className="prod-img" />
                                        <Link to="/Product_detail">
                                            <h3>Lorem ipsum dolor sit
                                                amet conse.</h3>
                                        </Link>
                                        <Link to="/cart" className="prod-cart"><img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/prod-cart.png" alt="prod-cart" /></Link>
                                        {/* </a> */}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="product-item mt-4">
                                        {/* <a href="https://demo-customlinks.com/esquare_dev/detail"> */}
                                        <h4> <span className="price">$150</span> <span className="dis-price">$100</span></h4>
                                        <div className="rate">
                                            <input type="radio" id="star1" name="rate" defaultValue={1} />
                                            <label htmlFor="star1" title="text">1 star</label>
                                            <input type="radio" id="star2" name="rate" defaultValue={2} />
                                            <label htmlFor="star2" title="text">2 stars</label>
                                            <input type="radio" id="star3" name="rate" defaultValue={3} />
                                            <label htmlFor="star3" title="text">3 stars</label>
                                            <input type="radio" id="star4" name="rate" defaultValue={4} />
                                            <label htmlFor="star4" title="text">4 stars</label>
                                            <input type="radio" id="star5" name="rate" defaultValue={5} />
                                            <label htmlFor="star5" title="text">5 stars</label>
                                        </div>
                                        <img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/wedding/wedding-2.jpg" alt="Product" className="prod-img" />
                                        <Link to="/Product_detail">
                                            <h3>Lorem ipsum dolor sit
                                                amet conse.</h3>
                                        </Link>
                                        <Link to="/cart" className="prod-cart"><img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/prod-cart.png" alt="prod-cart" /></Link>
                                        {/* </a> */}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="product-item mt-4">
                                        {/* <a href="https://demo-customlinks.com/esquare_dev/detail"> */}
                                        <h4> <span className="price">$150</span> <span className="dis-price">$100</span></h4>
                                        <div className="rate">
                                            <input type="radio" id="star1" name="rate" defaultValue={1} />
                                            <label htmlFor="star1" title="text">1 star</label>
                                            <input type="radio" id="star2" name="rate" defaultValue={2} />
                                            <label htmlFor="star2" title="text">2 stars</label>
                                            <input type="radio" id="star3" name="rate" defaultValue={3} />
                                            <label htmlFor="star3" title="text">3 stars</label>
                                            <input type="radio" id="star4" name="rate" defaultValue={4} />
                                            <label htmlFor="star4" title="text">4 stars</label>
                                            <input type="radio" id="star5" name="rate" defaultValue={5} />
                                            <label htmlFor="star5" title="text">5 stars</label>
                                        </div>
                                        <img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/women/women-2.jpg" alt="Product" className="prod-img" />
                                        <Link to="/Product_detail">
                                            <h3>Lorem ipsum dolor sit
                                                amet conse.</h3>
                                        </Link>
                                        <Link to="/cart" className="prod-cart"><img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/prod-cart.png" alt="prod-cart" /></Link>
                                        {/* </a> */}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="product-item mt-4">
                                        {/* <a href="https://demo-customlinks.com/esquare_dev/detail"> */}
                                        <h4> <span className="price">$150</span> <span className="dis-price">$100</span></h4>
                                        <div className="rate">
                                            <input type="radio" id="star1" name="rate" defaultValue={1} />
                                            <label htmlFor="star1" title="text">1 star</label>
                                            <input type="radio" id="star2" name="rate" defaultValue={2} />
                                            <label htmlFor="star2" title="text">2 stars</label>
                                            <input type="radio" id="star3" name="rate" defaultValue={3} />
                                            <label htmlFor="star3" title="text">3 stars</label>
                                            <input type="radio" id="star4" name="rate" defaultValue={4} />
                                            <label htmlFor="star4" title="text">4 stars</label>
                                            <input type="radio" id="star5" name="rate" defaultValue={5} />
                                            <label htmlFor="star5" title="text">5 stars</label>
                                        </div>
                                        <img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/wedding/wedding-1.jpg" alt="Product" className="prod-img" />
                                        <Link to="/Product_detail">
                                            <h3>Lorem ipsum dolor sit
                                                amet conse.</h3>
                                        </Link>
                                        <Link to="/cart" className="prod-cart"><img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/prod-cart.png" alt="prod-cart" /></Link>
                                        {/* </a> */}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="product-item mt-4">
                                        {/* <a href="https://demo-customlinks.com/esquare_dev/detail"> */}
                                        <h4> <span className="price">$150</span> <span className="dis-price">$100</span></h4>
                                        <div className="rate">
                                            <input type="radio" id="star1" name="rate" defaultValue={1} />
                                            <label htmlFor="star1" title="text">1 star</label>
                                            <input type="radio" id="star2" name="rate" defaultValue={2} />
                                            <label htmlFor="star2" title="text">2 stars</label>
                                            <input type="radio" id="star3" name="rate" defaultValue={3} />
                                            <label htmlFor="star3" title="text">3 stars</label>
                                            <input type="radio" id="star4" name="rate" defaultValue={4} />
                                            <label htmlFor="star4" title="text">4 stars</label>
                                            <input type="radio" id="star5" name="rate" defaultValue={5} />
                                            <label htmlFor="star5" title="text">5 stars</label>
                                        </div>
                                        <img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/wedding/wedding-2.jpg" alt="Product" className="prod-img" />
                                        <Link to="/Product_detail">
                                            <h3>Lorem ipsum dolor sit
                                                amet conse.</h3>
                                        </Link>
                                        <Link to="/cart" className="prod-cart"><img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/prod-cart.png" alt="prod-cart" /></Link>
                                        {/* </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row wow fadeInUp">
                        <div className="col-lg-3">
                            <div className="product-item mt-4">
                                {/* <a href="https://demo-customlinks.com/esquare_dev/detail"> */}
                                <h4> <span className="price">$150</span> <span className="dis-price">$100</span></h4>
                                <div className="rate">
                                    <input type="radio" id="star1" name="rate" defaultValue={1} />
                                    <label htmlFor="star1" title="text">1 star</label>
                                    <input type="radio" id="star2" name="rate" defaultValue={2} />
                                    <label htmlFor="star2" title="text">2 stars</label>
                                    <input type="radio" id="star3" name="rate" defaultValue={3} />
                                    <label htmlFor="star3" title="text">3 stars</label>
                                    <input type="radio" id="star4" name="rate" defaultValue={4} />
                                    <label htmlFor="star4" title="text">4 stars</label>
                                    <input type="radio" id="star5" name="rate" defaultValue={5} />
                                    <label htmlFor="star5" title="text">5 stars</label>
                                </div>
                                <img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/wedding/wedding-3.jpg" alt="Product" className="prod-img" />
                                <Link to="/Product_detail">
                                    <h3>Lorem ipsum dolor sit
                                        amet conse.</h3>
                                </Link>
                                <Link to="/cart" className="prod-cart"><img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/prod-cart.png" alt="prod-cart" /></Link>
                                {/* </a> */}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="product-item mt-4">
                                {/* <a href="https://demo-customlinks.com/esquare_dev/detail"> */}
                                <h4> <span className="price">$150</span> <span className="dis-price">$100</span></h4>
                                <div className="rate">
                                    <input type="radio" id="star1" name="rate" defaultValue={1} />
                                    <label htmlFor="star1" title="text">1 star</label>
                                    <input type="radio" id="star2" name="rate" defaultValue={2} />
                                    <label htmlFor="star2" title="text">2 stars</label>
                                    <input type="radio" id="star3" name="rate" defaultValue={3} />
                                    <label htmlFor="star3" title="text">3 stars</label>
                                    <input type="radio" id="star4" name="rate" defaultValue={4} />
                                    <label htmlFor="star4" title="text">4 stars</label>
                                    <input type="radio" id="star5" name="rate" defaultValue={5} />
                                    <label htmlFor="star5" title="text">5 stars</label>
                                </div>
                                <img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/women/women-2.jpg" alt="Product" className="prod-img" />
                                <Link to="/Product_detail">
                                    <h3>Lorem ipsum dolor sit
                                        amet conse.</h3>
                                </Link>
                                <Link to="/cart" className="prod-cart"><img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/prod-cart.png" alt="prod-cart" /></Link>
                                {/* </a> */}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="product-item mt-4">
                                {/* <a href="https://demo-customlinks.com/esquare_dev/detail"> */}
                                <h4> <span className="price">$150</span> <span className="dis-price">$100</span></h4>
                                <div className="rate">
                                    <input type="radio" id="star1" name="rate" defaultValue={1} />
                                    <label htmlFor="star1" title="text">1 star</label>
                                    <input type="radio" id="star2" name="rate" defaultValue={2} />
                                    <label htmlFor="star2" title="text">2 stars</label>
                                    <input type="radio" id="star3" name="rate" defaultValue={3} />
                                    <label htmlFor="star3" title="text">3 stars</label>
                                    <input type="radio" id="star4" name="rate" defaultValue={4} />
                                    <label htmlFor="star4" title="text">4 stars</label>
                                    <input type="radio" id="star5" name="rate" defaultValue={5} />
                                    <label htmlFor="star5" title="text">5 stars</label>
                                </div>
                                <img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/wedding/wedding-1.jpg" alt="Product" className="prod-img" />
                                <Link to="/Product_detail">
                                    <h3>Lorem ipsum dolor sit
                                        amet conse.</h3>
                                </Link>
                                <Link to="/cart" className="prod-cart"><img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/prod-cart.png" alt="prod-cart" /></Link>
                                {/* </a> */}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="product-item mt-4">
                                {/* <a href="https://demo-customlinks.com/esquare_dev/detail"> */}
                                <h4> <span className="price">$150</span> <span className="dis-price">$100</span></h4>
                                <div className="rate">
                                    <input type="radio" id="star1" name="rate" defaultValue={1} />
                                    <label htmlFor="star1" title="text">1 star</label>
                                    <input type="radio" id="star2" name="rate" defaultValue={2} />
                                    <label htmlFor="star2" title="text">2 stars</label>
                                    <input type="radio" id="star3" name="rate" defaultValue={3} />
                                    <label htmlFor="star3" title="text">3 stars</label>
                                    <input type="radio" id="star4" name="rate" defaultValue={4} />
                                    <label htmlFor="star4" title="text">4 stars</label>
                                    <input type="radio" id="star5" name="rate" defaultValue={5} />
                                    <label htmlFor="star5" title="text">5 stars</label>
                                </div>
                                <img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/wedding/wedding-2.jpg" alt="Product" className="prod-img" />
                                <Link to="/Product_detail">
                                    <h3>Lorem ipsum dolor sit
                                        amet conse.</h3>
                                </Link>
                                <Link to="/cart" className="prod-cart"><img src="https://demo-customlinks.com/esquare_dev/assets/front_assets/images/prod-cart.png" alt="prod-cart" /></Link>
                                {/* </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Wedding