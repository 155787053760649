import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios';

const Faqs = () => {
    const [faqs, setFaqs] = useState(null);
    const [cms, setCms] = useState(null);
    useEffect(() => {
        const questions = document.querySelectorAll(".question");
        questions.forEach((element) => {
            element.addEventListener("click", () => {
                const nextBox = element.nextElementSibling;
                const icons = element.querySelector(".icon");

                icons.style.transition = "0.5s linear";

                if (nextBox.classList.contains("active")) {
                    nextBox.classList.remove("active");
                    icons.style.transform = "rotate(0deg)";
                } else {
                    document
                        .querySelectorAll(".answer.active")
                        .forEach((nextBox) => nextBox.classList.remove("active"));
                    document
                        .querySelectorAll(".icon")
                        .forEach((normal) => (normal.style.transform = "rotate(0deg)"));

                    icons.style.transform = "rotate(180deg)";
                    nextBox.classList.add("active");
                }
            });
        });
    })
    useEffect(() => {
        GetFaqs()
        getCms();
    }, [])

    const getCms = async () => {
        await axios.get('https://esquare.demo-customproject.com:3002/get-cms/faqs').then(function (res) {
            setCms(res.data);
        })
    }
    const GetFaqs = async () => {
        await axios.get('https://esquare.demo-customproject.com:3002/get-faqs').then(function (res) {
            setFaqs(res.data);
        })
    }
    if (faqs === null || cms === null) {
        return <div className='Loading'></div>
    }
    return (
        <section className='faqs-sec'>
            <h2 className='text-center'>{cms.faq_title.title}</h2>
            <div className='container'>
                <div className='row align-items-center justify-content-center'>
                    <div className='col-md-11'>
                        <ul className="faqs-wrapper">
                            {
                                faqs.map((item) => {
                                    return <li className="box">
                                        <div className="question">
                                            <p className="question__text">{item.question}</p>
                                            <i className="fa-solid fa-caret-down icon" style={{ transform: 'rotate(0deg)' }} />
                                        </div>
                                        <div className="answer">
                                            <p className="answer__text">{item.answer}</p>
                                        </div>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faqs