import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useProductContext } from '../context/productcontext';
import ProductBox from '../components/ProductBox';

const JustCategory = () => {
    const { type, cate } = useParams();
    const { products, getCatg, getParCatg } = useProductContext();
    let typ = null;
    let categ = '';
    if(getParCatg !== null && getCatg !== null){
        getParCatg.filter((cat) => {
            return type.toLowerCase() === cat.name.toLowerCase() 
        }).map((cat) => {
            typ = cat.id;
        })
        if(cate !== undefined){
            getCatg.filter((cat) => {
                return cate.toLowerCase() === cat.name.toLowerCase() 
            }).map((cat) => {
                categ = cat.id;
            })
        }
    }
    if(typ === null){
        return <div className='Loading'></div>
    }

    return (
        <section className="productsec product-page-sec all-section">
            <div className="container">
                <div className="row">
                    {cate === undefined || cate === '' ? false :
                        <div className='col-lg-4 col-md-6'>
                            <div className='cate-boxxtype'>
                                <h2>Category : {cate}</h2>
                            </div>
                        </div>}
                </div>
                <div className='row mt-4'>
                    {
                        products.filter((pd) => {
                            if(cate === undefined){
                                return pd
                            }else{
                                return categ === pd.sub_category;
                            }
                        }).map((prd, key) => {
                            return <div className="col-lg-2 col-md-3 mb-4"><ProductBox {...prd} /></div>
                        })
                    }
                    <div className='notfound'>No Products Found</div>
                </div>
            </div>
        </section>
    )
}

export default JustCategory