import React, { useEffect, useState } from 'react'
import { useProductContext } from '../context/productcontext';
import secureLocalStorage from 'react-secure-storage';
import $ from 'jquery'
import { omit } from 'lodash'
import axios from 'axios';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";



function Checkout() {
    if (secureLocalStorage.getItem('cart_data') == null || Object.keys(secureLocalStorage.getItem('cart_data')).length === 0) {
        window.location.replace('/shop');
    }
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    

    const { products } = useProductContext();
    const [order_summary, SetOrder_Summary] = useState(null);
    const [cartItem, setcartItem] = useState(null);
    const [rate_list, setRates] = useState(null);
    const [disc, setDics] = useState(0);
    const [Pkey, setPkey] = useState();
    const [Ckey, setCkey] = useState(null);
    const [ORD_ID, Order_ID] = useState(null);
    const [stripePromise, setStripePromise] = useState(null);
    const [order, setOrder] = useState({
        first_name: '',
        last_name: '',
        country_name: 'ukMainland',
        street_address: '',
        town: '',
        state: '',
        zip: '',
        phone: '',
        email: '',
        payment_type: 'paypal'
    });
    const [errors, setErrors] = useState({});

    const GetRates = async () => {
        await axios.get('https://esquare.demo-customproject.com:3002/get-rates').then(res => {
            setRates(res.data)
        });
    }

    useEffect(() => {
        if (secureLocalStorage.getItem('discount') !== null) {
            setDics(parseInt(secureLocalStorage.getItem('discount')))
        } else {
            setDics(0);
        }
    }, [])
    useEffect(() => {
        GetRates();
    }, [disc])
    useEffect(() => {
        if(rate_list){
            CartData(order.country_name)
        }
    }, [rate_list])

    function CartData(det) {
        const val = secureLocalStorage.getItem('cart_data');
        if (val) {
            let dat = [];
            let totalCost = 0;
            Object.keys(val).forEach((key) => {
                const item = JSON.parse(val[key]);
                dat.push(item);
                console.log(det)
                totalCost += parseFloat(calculateCost(item.weight, item.length, item.girth, det, rate_list));
            });
            setCost(totalCost.toFixed(2))
            orderSummary(totalCost.toFixed(2))
            setcartItem(dat);
        }
    }
    
    let err = {};
    const validate2 = (name, value) => {
        if (name === 'first_name')
            if (value === null || value == undefined || value == '') {
                err = { ...err, first_name: 'Field cannot be empty' }
            } else {
                let newObj = omit(errors, "first_name");
                setErrors(newObj);
            }
        if (name === 'last_name')
            if (value === null || value == undefined || value == '') {
                err = { ...err, last_name: 'Field cannot be empty' }
            } else {
                let newObj = omit(errors, "last_name");
                setErrors(newObj);
            }
        if (name === 'country_name')
            if (value === null || value == undefined || value == '') {
                err = { ...err, country_name: 'Field cannot be empty' }
            } else {
                let newObj = omit(errors, "country_name");
                setErrors(newObj);
            }
        if (name === 'street_address')
            if (value === null || value == undefined || value == '') {
                err = { ...err, street_address: 'Field cannot be empty' }
            } else {
                let newObj = omit(errors, "street_address");
                setErrors(newObj);
            }
        if (name === 'town')
            if (value === null || value == undefined || value == '') {
                err = { ...err, town: 'Field cannot be empty' }
            } else {
                let newObj = omit(errors, "town");
                setErrors(newObj);
            }
        if (name === 'state')
            if (value === null || value == undefined || value == '') {
                err = { ...err, state: 'Field cannot be empty' }
            } else {
                let newObj = omit(errors, "state");
                setErrors(newObj);
            }
        if (name === 'zip')
            if (value === null || value == undefined || value == '') {
                err = { ...err, zip: 'Field cannot be empty' }
            } else {
                let newObj = omit(errors, "zip");
                setErrors(newObj);
            }
        if (name === 'phone') {
            if (value === null || value == undefined || value == '') {
                err = { ...err, phone: 'Field cannot be empty' }
            }
            else if (
                !new RegExp(/^\+?[0-9]\d{1,20}$/).test(value)
            ) {
                err = { ...err, phone: 'Invalid Phone Number' }
            } else {
                let newObj = omit(errors, "phone");
                setErrors(newObj);
            }
        }
        if (name === 'email') {
            if (value === null || value == undefined || value == '') {
                err = { ...err, email: 'Field cannot be empty' }
            } else if (
                !new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
            ) {
                err = { ...err, email: 'Invalid Email Address' }
            } else {
                let newObj = omit(errors, "email");
                setErrors(newObj);
            }
        }
        setErrors(err)
    }
    function orderSummary(shipping) {
        const ship = parseFloat(shipping)
        var values = secureLocalStorage.getItem('cart_data');
        if (values !== null) {
            let subt = 0;
            Object.keys(values).map((item) => {
                let itm = JSON.parse(values[item]);
                subt = subt + (itm.price * itm.qunatity);
            })
            const order = {
                subtotal: subt,
                shipping: ship,
                tax: 0,
                discount: disc,
                total: (subt - (disc * subt / 100)) + ship,
            }
            console.log(order)
            secureLocalStorage.setItem("order", JSON.stringify(order));
            const data = JSON.parse(secureLocalStorage.getItem("order"));
            SetOrder_Summary(data);
        }
    }
    const [vvlid, setvvlid] = useState(false)
    const [shipping, setCost] = useState(null);
    const calculateCost = (weight1, length1, girth1, destination, rates) => {
        const weight = parseInt(weight1, 10);
        const length = parseInt(length1, 10);
        const girth = parseInt(girth1, 10);
    
        if (isNaN(weight) || isNaN(length) || isNaN(girth)) return alert('Please fill in all fields.');
        if (length > 120 || girth > 225) return alert('Length cannot exceed 120cm and girth cannot exceed 225cm.');
        if (weight > 15) return alert('Weight exceeds the maximum limit of 15kg.');
        const rate = rates.find(r => weight <= parseInt(r.maxWeight, 10) && length <= parseInt(r.maxLength, 10) && girth <= parseInt(r.maxGirth, 10));
        return rate ? rate[destination] : alert('No applicable rate found.');
    };
    
    const setVal = (e) => {
        setOrder((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        if(e.target.name === "country_name"){
            CartData(e.target.value)
        }
        let data = { cart: cartItem, ...order, ...order_summary, user_id: secureLocalStorage.getItem('login_data') === null ? 'guest' : JSON.parse(secureLocalStorage.getItem('login_data')).id };
        Object.keys(data).map((ket) => {
            validate2(ket, data[ket]);
        });
        validate2(e.target.name, e.target.value);
        if (Object.keys(err).length === 0) {
            setvvlid(true);
        } else {
            setvvlid(false);
        }
    }
    const Order = async (e) => {
        e.preventDefault();
        e.target.classList.add('loadin');
        let data = { cart: cartItem, ...order, ...order_summary, user_id: secureLocalStorage.getItem('login_data') === null ? 'guest' : JSON.parse(secureLocalStorage.getItem('login_data')).id };
        Object.keys(data).map((ket) => {
            validate2(ket, data[ket]);
        });
        if (Object.keys(err).length === 0) {
            await axios.post(`https://esquare.demo-customproject.com:3002/post-order`, data).then((res) => {
                if (res.status == 200) {
                    e.target.classList.add('d-none');
                    setCkey(res.data.Ckey);
                    Order_ID(res.data.ord_id);
                } else {
                    e.target.classList.remove('loadin');
                    $('.msg-box').removeClass('green')
                    $('.msg-box').addClass('red').text(res.data.message);
                }
            })
        } else {
            e.target.classList.remove('loadin');
        }
    }
    useEffect(() => {
        axios.post(`https://esquare.demo-customproject.com:3002/PKEY_GET`).then((res) => {
            setPkey(res.data.key);
            setStripePromise(loadStripe(res.data.key));
        })
    }, [])

    // This value is from the props in the UI
    const style = { "layout": "vertical" };
    let ord__id = null;
    function createOrder() {
        let data = { cart: cartItem, ...order, ...order_summary, user_id: secureLocalStorage.getItem('login_data') === null ? 'guest' : JSON.parse(secureLocalStorage.getItem('login_data')).id };
        return fetch("https://esquare.demo-customproject.com:3002/post-order", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((order) => {
                console.log(order)
                ord__id = order.ord_id;
                return order.id;
            });
    }
    function onApprove(data) {
        return fetch(`https://esquare.demo-customproject.com:3002/orders/${data.orderID}/capture`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                orderID: data.orderID,
            }),
        })
            .then((response) => response.json())
            .then((orderData) => {
                console.log(orderData);
                if (orderData.status === "COMPLETED") {
                    axios.post(`https://esquare.demo-customproject.com:3002/orders/success-paypal`, { id: orderData.id, ord_id: ord__id }).then((res) => {
                        if (res.data.status == '200') {
                            $('.msg-box').removeClass('red')
                            $('.msg-box').addClass('green').text(res.data.message);
                            secureLocalStorage.removeItem("order")
                            secureLocalStorage.removeItem("cart_data")
                            secureLocalStorage.removeItem("discount")
                            const Pcart = secureLocalStorage.getItem('Pcart_data');
                            secureLocalStorage.setItem('cart_data', Pcart);
                            setTimeout(function () {
                                window.location.replace('/orders');
                            }, 2000)
                        } else {
                            $('.msg-box').removeClass('green')
                            $('.msg-box').addClass('red').text(res.data.message);
                        }
                    })
                } else {
                    console.log(orderData, 'asdas');
                }
            });
    }

    const ButtonWrapper = ({ showSpinner }) => {
        const [{ isPending }] = usePayPalScriptReducer();

        return (
            <>
                {(showSpinner && isPending) && <div className="spinner" />}
                <PayPalButtons
                    style={style}
                    disabled={false}
                    forceReRender={[style]}
                    fundingSource={undefined}
                    createOrder={createOrder}
                    onApprove={onApprove}
                />
            </>
        );
    }

    if (order_summary === null || cartItem === null) {
        return <div className='Loading'></div>
    }

    return (
        <>
            <section className="checkout_page all-section all-side">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-6">
                            <div className="billing_form wow fadeInLeft">
                                <h3>Billing Details</h3>
                                <form>
                                    <div className="row">
                                        <div className="col-sm-6 col-lg-6 col-md-12">
                                            <label>First Name*</label>
                                            {errors.first_name ? <div className='errorr mb-0'>{errors.first_name}</div> : false}
                                            <input type="text" autoComplete="new-phone" name="first_name" onChange={setVal} />
                                        </div>
                                        <div className="col-sm-6 col-lg-6 col-md-12">
                                            <label>Last Name*</label>
                                            {errors.last_name ? <div className='errorr mb-0'>{errors.last_name}</div> : false}
                                            <input type="text" autoComplete="new-phone" name="last_name" onChange={setVal} />
                                        </div>
                                        <div className="col-sm-12 col-lg-12 col-md-12">
                                            <label>Company Name (Optional)</label>
                                            <input type="text" autoComplete="new-phone" name="company_name" onChange={setVal} />
                                        </div>
                                        <div className="col-sm-12 col-lg-12 col-md-12">
                                            <label>Region *</label>
                                            {errors.country_name ? <div className='errorr mb-0'>{errors.country_name}</div> : false}
                                            {/* <input type="text" autoComplete="new-phone" name="country_name" placeholder="United Kingdom" onChange={setVal} /> */}
                                            <select name="country_name" onChange={setVal}>
                                                <option value="ukMainland">UK Mainland</option>
                                                <option value="highlandsIslands">Highlands, Islands</option>
                                                <option value="northernIreland">Northern Ireland</option>
                                            </select>
                                        </div>
                                        <div className="col-sm-12 col-lg-12 col-md-12">
                                            <label>Street Address*</label>
                                            {errors.street_address ? <div className='errorr mb-0'>{errors.street_address}</div> : false}
                                            <input type="text" autoComplete="new-phone" name="street_address" placeholder="House Number and Street Name" onChange={setVal} />
                                        </div>
                                        <div className="col-sm-6 col-lg-6 col-md-12">
                                            <label>Town / City *</label>
                                            {errors.town ? <div className='errorr mb-0'>{errors.town}</div> : false}
                                            <input type="text" autoComplete="new-phone" name="town" onChange={setVal} />
                                        </div>
                                        <div className="col-sm-6 col-lg-6 col-md-12">
                                            <label>State*</label>
                                            {errors.state ? <div className='errorr mb-0'>{errors.state}</div> : false}
                                            <input type="text" autoComplete="new-phone" name="state" onChange={setVal} />
                                        </div>
                                        <div className="col-sm-6 col-lg-6 col-md-12">
                                            <label>Postal Code*</label>
                                            {errors.zip ? <div className='errorr mb-0'>{errors.zip}</div> : false}
                                            <input type="number" autoComplete="new-phone" name="zip" onChange={setVal} />
                                        </div>
                                        <div className="col-sm-6 col-lg-6 col-md-12">
                                            <label>Phone*</label>
                                            {errors.phone ? <div className='errorr mb-0'>{errors.phone}</div> : false}
                                            <input type="number" autoComplete="new-phone" name="phone" onChange={setVal} />
                                        </div>
                                        <div className="col-sm-12 col-lg-12 col-md-12">
                                            <label>Email Address*</label>
                                            {errors.email ? <div className='errorr mb-0'>{errors.email}</div> : false}
                                            <input type="email" autoComplete="new-email" name="email" onChange={setVal} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="cart_sidebar wow fadeInRight">
                                <h3>Your Order</h3>
                                <h5 className="h-sub-prod">Product</h5>
                                <ul className="cart_lst">
                                    <li className='d-block'>
                                        {cartItem.length !== 0 ? <>
                                            {
                                                cartItem.map((cart) => {
                                                    return <>
                                                        {
                                                            products.filter((elem) => {
                                                                return cart.price !== undefined && elem.id === cart.id;
                                                            }).map((elem) => {
                                                                return <div className='check-out-crt'>
                                                                    <div className="table-space">
                                                                        <div className="row">
                                                                            <div className="col-lg-3 no-padding">
                                                                                <div className="product-img">
                                                                                    <img src={`https://esquare.demo-customproject.com/admin/backend/public/uploads/products/ath_${elem.user_id}/${elem.thumbnail}`} className="img-fluid" alt="img" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-9 no-space">
                                                                                <h3>{elem.title}</h3>
                                                                                <div className='variants-crt'>
                                                                                    {
                                                                                        Object.keys(cart.vartions).map((ver) => {
                                                                                            return <h4>{ver}: <span>{cart.vartions[ver]}</span></h4>
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <h4>£{numberWithCommas(cart.price * cart.qunatity)}</h4>
                                                                </div>
                                                            })
                                                        }
                                                    </>
                                                })
                                            } </> : <div className='notfound cart-not text-center'>
                                            <img src='images/cart-empty.png' />
                                            <h2>No items in cart</h2>
                                        </div>}
                                    </li>
                                    <li className='last'>Subtotal <span className="chk-price">£{numberWithCommas(order_summary.subtotal)}</span></li>
                                    <li className='last'>Discount <span className="chk-price">{numberWithCommas(order_summary.discount)}%</span></li>
                                    {shipping && <li className='last'>Shipping <span className="chk-price">£ {shipping}</span></li>}
                                </ul>
                                <h5 className="h-sub mt-3">
                                    Total <span className="chk-price">£{numberWithCommas(order_summary.total.toFixed(2))}</span>
                                </h5>

                                {vvlid === true ? <>
                                    <h6 className="payment-h">Payment Method</h6>
                                    <ul className="radiosss radiosss-payments">
                                        <li>
                                            <input type="radio" onClick={setVal} name="payment_type" value='paypal' defaultChecked />
                                            <label className="label-black" htmlFor="direct">Paypal</label>
                                            <div className="card card-body mt-4">
                                                <div style={{ maxWidth: "100%" }}>
                                                    <PayPalScriptProvider options={{ clientId: "AV1FKYwFzkgWfayVDYrM7pq4supZyq_PLrF2B_5dEVThFi_dz_yzb0HwfOu45Hg_CvcFxMZgPton8yQx", components: "buttons", currency: "USD", "disable-funding": "paylater,card" }}>
                                                        <ButtonWrapper showSpinner={false} />
                                                    </PayPalScriptProvider>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <input type="radio" onClick={setVal} name="payment_type" value='stripe' />
                                            <label className="label-black" htmlFor="cp">Stripe</label>
                                            <div className="card card-body">
                                                <a href="javascript:void(0)" onClick={Order} className="checkout_btn mt-4 btn13">Pay with <img src='images/Stripe-logo.png' /></a>
                                            </div>
                                        </li>
                                    </ul>
                                </> : false}
                            </div>
                        </div>
                    </div>
                    <p className='msg-box'></p>
                </div>
            </section>
            {
                stripePromise !== undefined && Ckey != null ?
                    <Elements stripe={stripePromise} options={{ clientSecret: Ckey }}><CheckoutForm ORD_ID={ORD_ID} /></Elements>
                    : false
            }

        </>
    )
}

export default Checkout