import React from 'react'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import  secureLocalStorage  from  "react-secure-storage";

const Account_Sidebar = () => {
    if (secureLocalStorage.getItem('login_data') == null) {
        window.location.replace('/login');
    }
    useEffect(() => {
        var str = window.location.href.toLowerCase();
        $(".side-bar>a").each(function () {
            if (str.indexOf(this.href.toLowerCase()) > -1) {
                $(".side-bar>a.active").removeClass("active");
                $(this).addClass("active");
            }
        });
    })
    const ConnectStripe = async (e) => {
        if (secureLocalStorage.getItem('login_data') !== null) {
            e.preventDefault();
            if (secureLocalStorage.getItem('stripe_getway') == 'false') {
                $('body').addClass('Loading')
                await axios.post(`https://beeta.shica.io:8448/account_session/${JSON.parse(secureLocalStorage.getItem('login_data')).email}`).then(res => {
                    if (res.data.url) {
                        window.location.replace(res.data.url)
                    }
                })
            }else{
                if (window.confirm("Are you sure you want to switch stripe account?") == true) {
                    $('body').addClass('Loading')
                    await axios.post(`https://beeta.shica.io:8448/account_session/${JSON.parse(secureLocalStorage.getItem('login_data')).email}`).then(res => {
                        if (res.data.url) {
                            window.location.replace(res.data.url)
                        }
                    })
                }
            }
        }
    }
    const Logout = (e) => {
        e.preventDefault();
        secureLocalStorage.removeItem('login_data');
        secureLocalStorage.removeItem('accessToken');
        window.location.href = "/"
    }
    return (
        <div className="side-bar">
            {JSON.parse(secureLocalStorage.getItem('login_data')).role == 2 ? <Link to="/my-store"><i className="fa-light fa-folder"></i> My Store </Link> : false}
            <Link to="/profile"><i className="fa-light fa-user" /> Profile </Link>
            {JSON.parse(secureLocalStorage.getItem('login_data')).role == 2 ? <Link to="/add-products"><i className="fa-light fa-file-arrow-up"></i> Add New product </Link> : false}
            {JSON.parse(secureLocalStorage.getItem('login_data')).role == 2 ? <Link to="/orders-listing"><i className="fa-sharp fa-light fa-rectangle-history"></i> Order Recieved </Link> : false}
            {/* {JSON.parse(secureLocalStorage.getItem('login_data')).role == 2 ? <a href='#' onClick={ConnectStripe}><i className="fa-brands fa-stripe-s"></i> Connect Stripe </a> : false} */}
            <Link to="/wishlist"><i className="fa-light fa-heart"></i> Wishlist </Link>
            {JSON.parse(secureLocalStorage.getItem('login_data')).role !== 2 ? <Link to="/orders"><i className="fa-light fa-lock-keyhole-open"></i> Orders </Link> : false}
            <a href="#" onClick={Logout}><i className="fa-sharp fa-light fa-arrow-right-from-bracket fa-flip-horizontal"></i> Logout</a>    
        </div>
    )
}

export default Account_Sidebar