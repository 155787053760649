import $ from 'jquery'
import { useLocation, useNavigate } from 'react-router-dom';

const Scripts = () => {

    const navigate = useNavigate();

    setTimeout(function(){
        $('.msg-box').removeClass('green')
        $('.msg-box').removeClass('red')
    },5000)

    setTimeout(function(){
        localStorage.removeItem('forgot_email')
    },3600000)

    // $('#scripts').html("");
    // var addf = document.createElement('script');
    // addf.src = "js/jquery-3.6.0.min.js";
    // document.getElementById("scripts").appendChild(addf);

    // var addf2 = document.createElement('script');
    // addf2.src = "js/wow.js";
    // document.getElementById("scripts").appendChild(addf2);

    // var addf3 = document.createElement('script');
    // addf3.src = "slick/slick.js";
    // document.getElementById("scripts").appendChild(addf3);

    // var addf4 = document.createElement('script');
    // addf4.src = "slick/slick.min.js";
    // document.getElementById("scripts").appendChild(addf4);

    // var addf5 = document.createElement('script');
    // addf5.src = "js/jquery.slicknav.js";
    // document.getElementById("scripts").appendChild(addf5);

    // var addf6 = document.createElement('script');
    // addf6.src = "js/fancybox.js";
    // document.getElementById("scripts").appendChild(addf6);

    // var addf7 = document.createElement('script');
    // addf7.src = "js/bootstrap.js";
    // document.getElementById("scripts").appendChild(addf7);

    // var addf8 = document.createElement('script');
    // addf8.src = "js/custom.js";
    // document.getElementById("scripts").appendChild(addf8);

    // var addf9 = document.createElement('script');
    // addf9.src = "js/font.js";
    // document.getElementById("scripts").appendChild(addf9);

    if (localStorage.getItem('login_data') != null) {
        setTimeout(function () {
            localStorage.removeItem('login_data');
            navigate('/');
        }, 3600000)
    }
}

export default Scripts