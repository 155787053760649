import React from 'react'
import { useEffect } from 'react';
import $ from 'jquery';
import { useNavigate, Link } from 'react-router-dom'
import { useState } from 'react';
import axios from 'axios';
import { useProductContext } from '../context/productcontext';
import secureLocalStorage from "react-secure-storage";

const Header = () => {

    if (secureLocalStorage.getItem('login_attmpt') == null) {
        secureLocalStorage.setItem('login_attmpt', 5);
    }
    const { getCatg, getParCatg, products } = useProductContext();
    const [CMS, setCMS] = useState(null);
    const [serchkey, setSearchKey] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        GetCMS();
        getLogo();
    }, [])
    const GetCMS = async () => {
        await axios.get(`https://esquare.demo-customproject.com:3002/site-info/`).then((res) => {
            setCMS(res.data);
        })
    }
    const [logo, setLogo] = useState(null);
    const getLogo = async () => {
        await axios.get('https://esquare.demo-customproject.com:3002/getlogo').then(res => {
            setLogo(res.data[0].img_path);
        })
    }

    // Cart
    const [cart_count, SetCount] = useState(0);
    const CartCount = () => {
        const cart = secureLocalStorage.getItem('cart_data');
        if (cart !== null) {
            SetCount(Object.keys(cart).length);
        }
    }
    useEffect(() => {
        CartCount();
        GetWishlist_count()
        window.addEventListener("storage", CartCount);
        return () => window.removeEventListener("storage", CartCount);
    }, [])
    // Cart


    useEffect(() => {
        var burger = document.querySelector('.menu-btn')
        var path1 = document.querySelector('.path1')
        var path2 = document.querySelector('.path2')
        var mline = document.querySelector('.mline')
        $('.has-cato>span').unbind().click(function (e) {
            e.preventDefault();
            $(this).parent().find('>.sub-cato-box').addClass('active');
            $(this).closest('nav').scrollTop(0)
        })
        $('.g-back').unbind().click(function () {
            $(this).closest('.sub-cato-box').removeClass('active')
        })
        $("#menu-btn").unbind().click(function () {
            path1.classList.toggle('cross');
            path2.classList.toggle('cross');
            mline.classList.toggle('hide');
            if (window.innerWidth < 768) {
                $("html").toggleClass("overflow-hidden");
            } else {
                $("html").toggleClass("overflow-hidden me-10px");

            }
            $("#menu-box").fadeToggle(500);
        })
        $("#menu-box a").unbind().click(function () {
            path1.classList.toggle('cross');
            path2.classList.toggle('cross');
            mline.classList.toggle('hide');
            if (window.innerWidth < 768) {
                $("html").toggleClass("overflow-hidden");
            } else {
                $("html").toggleClass("overflow-hidden me-10px");

            }
            $("#menu-box").fadeToggle(500);
        })
    })

    const GetWishlist_count = async () => {
        await axios.post(`https://esquare.demo-customproject.com:3002/get-user-wishlist/${JSON.parse(secureLocalStorage.getItem('login_data'))?.id}`, null, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
            $('.center-sec-icon ul li i.far.fa-heart ~ span').text(res.data.length);
        });
    }

    const [logoutbtn, setLogoutbtn] = useState(0);

    useEffect(() => {
        if (secureLocalStorage.getItem('login_data') != null) {
            setLogoutbtn(1);
        }
    })

    const Logout = (e) => {
        e.preventDefault();
        secureLocalStorage.removeItem('login_data');
        secureLocalStorage.removeItem('accessToken');
        window.location.href = "/"
    }
    if (getParCatg !== null && getCatg !== null) {
        return (

            <header>
                <div className="topSec">
                    <Link to="#" className="menu-btn" id="menu-btn">
                        <svg viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="path1" d="M1.5 7L27.5 33C27.5 33 30.5 36 36.5 40.5C42.5 45 48 33.5 41.5 33C35 32.5 2 33 2 33" stroke="White" strokeWidth={2} />
                            <path className="path2" d="M2 33L28 7C28 7 33.5 1 37 1C40.5 1 43 6.20692 40 7C37 7.79308 1 7 1 7" stroke="White" strokeWidth={2} />
                            <path className="mline" d="M1.5 20H28.5" stroke="white" strokeWidth={2} />
                        </svg>
                    </Link>
                    <div className="menu-box" id="menu-box">
                        <nav>
                            {
                                getParCatg.map((p_cat) => {
                                    return <>
                                        <Link to={`/category/${p_cat.name.toLowerCase()}`}><h3>{p_cat.name}</h3></Link>
                                        <div className="has-cato">
                                            <span>What's New</span>
                                            <div className="sub-cato-box">
                                                <span className="g-back">
                                                    <i className="fa fa-angle-left" aria-hidden="true" /> back </span>
                                                <Link to="#">
                                                    <h3>What's New</h3>
                                                </Link>
                                                {
                                                    getCatg.filter((d) => { return d.parent_id === p_cat.id }).toReversed().map((c_cat, key) => {
                                                        if (key < 3) {
                                                            return <Link to={`/category/${p_cat.name.toLowerCase()}/${c_cat.name.toLowerCase()}`}>{c_cat.name}</Link>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                        {
                                            getCatg.filter((d) => { return d.parent_id === p_cat.id }).map((c_cat) => {
                                                return <Link to={`/category/${p_cat.name.toLowerCase()}/${c_cat.name.toLowerCase()}`}>{c_cat.name}</Link>
                                            })
                                        }

                                        <hr />
                                    </>
                                })
                            }
                        </nav>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-12 ">
                                <div className="topsec-left-text d-none d-lg-block">
                                    <h2>welcome to esquare</h2>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12 ">
                                <div className="topsec-right-text d-none d-lg-flex">
                                    {/* <div className="topsec-paga-link ">
                                        <ul>
                                            <li>
                                                <Link to="/checkout">checkout</Link>
                                            </li>
                                            <li>
                                                <Link to="/cart">cart</Link>
                                            </li>
                                            <li>
                                                <Link to="/faqs">faq</Link>
                                            </li>
                                            <li className="boader-no">
                                                <Link to="/about">about us</Link>
                                            </li>
                                        </ul>
                                    </div> */}
                                    {/* <div className="topsec-select">
                                        <select name="cars" id="cars" className="form-select">
                                            <option value="volvo">eng</option>
                                            <option value="saab">eng</option>
                                            <option value="mercedes">eng</option>
                                            <option value="audi">eng</option>
                                        </select>
                                    </div> */}
                                    <div className="topsec-soical-link">
                                        <ul>
                                            {CMS !== null ?
                                                CMS.map((item) => {
                                                    if (item.type == 'facebook') {
                                                        return <li><a href={item.value} target='_blank'><i className="fab fa-facebook" /></a></li>;
                                                    }
                                                })
                                                : false}
                                            {CMS !== null ?
                                                CMS.map((item) => {
                                                    if (item.type == 'instagram') {
                                                        return <li><a href={item.value} target='_blank'><i className="fab fa-instagram" /></a></li>;
                                                    }
                                                })
                                                : false}
                                            {CMS !== null ?
                                                CMS.map((item) => {
                                                    if (item.type == 'twitter') {
                                                        return <li><a href={item.value} target='_blank'><i className="fab fa-twitter" /></a></li>;
                                                    }
                                                })
                                                : false}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="center-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-12">
                                <div className="logo-sec">
                                    <Link to="/"><img src={"https://esquare.demo-customproject.com/admin/backend/public/uploads/" + logo} alt="img" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="center-input-box">
                                    <form onClick={(e) => {
                                        e.preventDefault();
                                        if (serchkey !== '') {
                                            navigate(`/search/${serchkey}`);
                                            setSearchKey('')
                                        }
                                    }}>
                                        <input type="text" name="search for product....." autoComplete='off' value={serchkey} onChange={(e) => { setSearchKey(e.target.value) }} placeholder="search for product....." />
                                        <button type="submit" className="center-input-box-icon">
                                            <i className="fas fa-search" aria-hidden="true" />
                                        </button>
                                        <div className="center-input-box-icon-2">
                                            <img src="images/search-box-icon.png" alt="img" />
                                        </div>
                                    </form>
                                    {
                                        serchkey !== '' && products.length !== 0 ?
                                            <div className='product-searched'>
                                                {
                                                    products.filter((elem) => {
                                                        return elem.title.toLowerCase().includes(serchkey.toLowerCase())
                                                    }).map((prd, key) => {
                                                        if (key < 10) {
                                                            return <div className='product-verticle product-item-mew'>
                                                                <img src={`https://esquare.demo-customproject.com/admin/backend/public/uploads/products/ath_${prd.user_id}/${prd.thumbnail}`} alt="" />
                                                                <div>
                                                                    <Link to={`/product-detail/${prd.id}`} onClick={() => { setSearchKey('') }}><h5>{prd.title}</h5></Link>
                                                                    <div>
                                                                        <span class="dis-price">${prd.price}</span>
                                                                        <strike>${prd.cut_price}</strike>
                                                                    </div>
                                                                    <div className='category-dbag'>
                                                                        <div>Type : <span>{
                                                                            getParCatg.map((tp) => {
                                                                                if (prd.type_category === tp.id) {
                                                                                    return tp.name;
                                                                                }
                                                                            })
                                                                        }</span></div>
                                                                        <div>Catogery : <span>{
                                                                            getCatg.map((tp) => {
                                                                                if (prd.sub_category === tp.id) {
                                                                                    return tp.name;
                                                                                }
                                                                            })
                                                                        }</span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    })
                                                }
                                                <div className='notfound'>No Products Found</div>
                                            </div>
                                            : false
                                    }
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12">
                                <div className="center-sec-icon">
                                    <ul>
                                        <li>
                                            <Link to={logoutbtn ? '/profile' : '/login'}>
                                                <i className="far fa-user" aria-hidden="true" />
                                                <h2>{logoutbtn ? 'accounts' : 'Login / Signup'}</h2>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/wishlist" className="whishlist-btn">
                                                <i className="far fa-heart" aria-hidden="true" />
                                                <h2>wishlist</h2>
                                                <span>0</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/cart">
                                                <i className="far fa-shopping-cart" aria-hidden="true" />
                                                <h2>cart</h2>
                                                <span>{cart_count}</span>
                                            </Link>
                                        </li>
                                        <li className={logoutbtn ? '' : 'd-none'}>
                                            <Link onClick={Logout}>
                                                <i className="far fa-sign-out" aria-hidden="true" />
                                                <h2>Logout</h2>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="menuSec">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-5 d-none d-lg-block">
                                <ul id="menu">
                                    <li className="active">
                                        <Link to="/"> home </Link>
                                    </li>
                                    <li>
                                        <Link to="/shop"> product </Link>
                                    </li>
                                    <li>
                                        <Link to="/testimonails">testimonial </Link>
                                    </li>
                                    <li className='drop-drown'>
                                        <a href='javascript:;'>Categories</a>
                                        <div className='drop-rtop'>
                                            {
                                                getParCatg.map((p_cat) => {
                                                    return <div>
                                                        <Link to={`/category/${p_cat.name.toLowerCase()}`}>{p_cat.name}</Link>
                                                        <div className='sub-dropwna'>
                                                            {
                                                                getCatg.filter((d) => { return d.parent_id === p_cat.id }).toReversed().map((c_cat, key) => {
                                                                    if (key < 3) {
                                                                        return <Link to={`/category/${p_cat.name.toLowerCase()}/${c_cat.name.toLowerCase()}`}>{c_cat.name}</Link>
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </li>
                                    <li className="boader-no d-block d-lg-none">
                                        <Link to="#">about us</Link>
                                    </li>
                                    <li className="d-block d-lg-none">
                                        <Link to="#">faq</Link>
                                    </li>
                                    <li className="d-block d-lg-none">
                                        <Link to="/checkout">checkout</Link>
                                    </li>
                                    <li className="d-block d-lg-none">
                                        <Link to="/cart">cart</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">contact us </Link>
                                    </li>
                                    <li className="d-block d-lg-none">
                                        <div className="topsec-soical-link">
                                            <ul>
                                                {CMS !== null ?
                                                    CMS.map((item) => {
                                                        if (item.type == 'facebook') {
                                                            return <li><a href={item.value} target='_blank'><i className="fab fa-facebook" /></a></li>;
                                                        }
                                                    })
                                                    : false}
                                                {CMS !== null ?
                                                    CMS.map((item) => {
                                                        if (item.type == 'instagram') {
                                                            return <li><a href={item.value} target='_blank'><i className="fab fa-instagram" /></a></li>;
                                                        }
                                                    })
                                                    : false}
                                                {CMS !== null ?
                                                    CMS.map((item) => {
                                                        if (item.type == 'twitter') {
                                                            return <li><a href={item.value} target='_blank'><i className="fab fa-twitter" /></a></li>;
                                                        }
                                                    })
                                                    : false}
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="d-block d-lg-none">
                                        <div className="topsec-select">
                                            <select name="cars" id="cars" className="form-select">
                                                <option value="volvo">eng</option>
                                                <option value="saab">eng</option>
                                                <option value="mercedes">eng</option>
                                                <option value="audi">eng</option>
                                            </select>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-7 col-md-12 col-sm-12">
                                <div className="menusec-right-sec">
                                    {/* <div className="menusec-loction chg">
                                        <ul>
                                            <li>
                                                <img src="images/header-icon.png" alt="img" />
                                            </li>
                                            <li>
                                                <h2>only this weekend</h2>
                                                <select name="cars" id="cars" className="form-select">
                                                    <option value="volvo">super discount</option>
                                                    <option value="saab">super discount</option>
                                                    <option value="mercedes">super discount</option>
                                                    <option value="audi">super discount</option>
                                                </select>
                                            </li>
                                        </ul>
                                    </div> */}
                                    <div className="menusec-loction chg">
                                        <ul>
                                            <li>
                                                <img src="images/header-icon2.png" alt="img" />
                                            </li>
                                            <li>
                                                <h2>Help Line</h2>
                                                <Link to="Tel:280 1902 12345">280 1902 12345</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>


        )
    }
}

export default Header
