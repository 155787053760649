import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import $ from 'jquery';
import secureLocalStorage from 'react-secure-storage';

const CheckoutForm = ({ORD_ID}) => {

    const stripe = useStripe();
    const elements = useElements();
    let url;
    if(secureLocalStorage.getItem('login_data') !== null){
        url = `https://esquare.demo-customproject.com/order-detail/${ORD_ID}`
    }else{
        url = `https://esquare.demo-customproject.com/thankyou`
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        $('.btn-theme.ss').addClass('loadin')

        if (!stripe || !elements) {
            return;
        }
        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: url,
            },
        });
        console.log(result);
        if (result.error) {
            $('.msg-box').addClass('red').text(result.error.message);
            $('.btn-theme.ss').removeClass('loadin')
        } else {
            
        }
    };

    return (
        <form onSubmit={handleSubmit} className='strip-checkout col-md-5'>
            <PaymentElement />
            <button disabled={!stripe} className='btn-theme mt-4 ss'>Pay Now</button>
        </form>
    );
};

export default CheckoutForm;