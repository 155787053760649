import axios from "axios";
import { createContext, useContext, useEffect, useReducer } from "react";
import reducer from "../reducer/ProductReducer"
import secureLocalStorage from "react-secure-storage";

const API = "https://esquare.demo-customproject.com:3002/live-products";
const AppContext = createContext();

const initailState = {
    isloading: false,
    isloadingDet: false,
    isError: false,
    products: [],
    featured: [],
    newArrival: [],
    productData: {},
    productOne: {},
    getCatg: null,
    getParCatg: null,
    stripeURL: null,
}

const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initailState);
    const auth = JSON.parse(secureLocalStorage.getItem('login_data'))

    const getProducts = async (url) => {
        dispatch({ type: "IS_LOADING" })
        try {
            const res = await axios.get(url);
            const products = await res.data.data;
            dispatch({ type: "MY_PRODUCTS", payload: products });
        } catch (error) {
            dispatch({ type: "IS_ERROR" })
        }
    };
    const getNewProducts = async (url) => {
        dispatch({ type: "IS_LOADING_NEW" })
        try {
            const res = await axios.get(url);
            const productsNew = await res.data.data;
            dispatch({ type: "MY_PRODUCTS_NEW", payload: productsNew });
        } catch (error) {
            dispatch({ type: "IS_ERROR_NEW" })
        }
    }
    const getDetail = async (url) => {
        dispatch({ type: "IS_LOADING_DETAIL" })
        try {
            const res = await axios.get(url);
            const productDetail = await res.data.data;
            dispatch({ type: "MY_PRODUCTS_DETAIL", payload: productDetail });
        } catch (error) {
            dispatch({ type: "IS_ERROR_DETAIL" })
        }
    }
    const getOneProduct = async (url) => {
        dispatch({ type: "IS_LOADING_ONE" })
        try {
            const res = await axios.get(url);
            const productone = await res.data.data;
            dispatch({ type: "MY_PRODUCTS_ONE", payload: productone });
        } catch (error) {
            dispatch({ type: "IS_ERROR_ONE" })
        }
    }
    const getCatogries = async (url) => {
        dispatch({ type: "IS_LOADING_CAT" })
        try {
            const res = await axios.get(url);
            const catogries = await res.data;
            dispatch({ type: "MY_PRODUCTS_CAT", payload: catogries });
        } catch (error) {
            dispatch({ type: "IS_ERROR_CAT" })
        }
    }
    const getPerCatogries = async (url) => {
        dispatch({ type: "IS_LOADING_CAT" })
        try {
            const res = await axios.get(url);
            const catogries = await res.data;
            dispatch({ type: "PAR_PRODUCTS_CAT", payload: catogries });
        } catch (error) {
            dispatch({ type: "IS_ERROR_CAT" })
        }
    }

    const CheckStripe = async (id, email) => {
        await axios.post(`https://esquare.demo-customproject.com:3002/check_stripe_session/${id}`).then(res => {
            if (res.data.capabilities.transfers == 'active') {
                if (secureLocalStorage.getItem('stripe_getway') == 'false') {
                    secureLocalStorage.setItem('stripe_getway', true);
                } else {
                    secureLocalStorage.setItem('stripe_getway', true);
                }
            } else {
                secureLocalStorage.setItem('stripe_getway', false);
                ConnectStripe(email)
            }
        })
    }

    const ConnectStripe = async (email) => {
        await axios.post(`https://esquare.demo-customproject.com:3002/account_session/${email}`).then(res => {
            if (res.data.url) {
                dispatch({ type: "STRIPE_URL", payload: res.data.url });
            }
        })
    }


    useEffect(() => {
        getProducts(API);
        if (auth?.role == 2) {
            CheckStripe(auth.id, auth.email);
        }
        // getNewProducts("https://bellamie.biz/admin/api/get-latest-products");
        // getOneProduct("https://bellamie.biz/admin/api/get-product/6")
        getCatogries("https://esquare.demo-customproject.com:3002/sub-categories/")
        getPerCatogries("https://esquare.demo-customproject.com:3002/categories/");
    }, [])

    return <AppContext.Provider value={{ ...state, getDetail, CheckStripe }} > {children} </AppContext.Provider>
};

const useProductContext = () => {
    return useContext(AppContext);
}
export { AppProvider, AppContext, useProductContext };