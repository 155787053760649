import React, { useEffect, useState } from 'react'
import axios from 'axios';
const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

const About = () => {
    const [cms, setCms] = useState(null);
    useEffect(() => {
        getCms();
    }, [])

    const getCms = async () => {
        await axios.get('https://esquare.demo-customproject.com:3002/get-cms/about').then(function (res) {
            setCms(res.data);
        })
    }
    if(cms === null){
        return <div className='Loading'></div>
    }
    return (
        <section className='about-sec'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <div className='about-cont'>
                            <h2>{cms.about_sec.title}</h2>
                            {renderHTML(cms.about_sec.description)}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <img className='w-100' src={`https://esquare.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.about_sec.image_path}`} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About